import React from 'react';
import {Link, NavLink} from "react-router-dom";

// import interface
import {IBasicUserDataProps} from './../../props/data';

// import services
import GeneralService from './../../services/general';
import AuthenticationService from './../../services/authentication';

// import assets
import LogoDark from './../../assets/images/logo-dark.png';

// import styles
import menubarStyles from './menubar.module.scss';

// import fabric ui
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { IContextualMenuProps } from 'office-ui-fabric-react/lib/ContextualMenu';
import { DefaultButton, ActionButton, CommandButton } from 'office-ui-fabric-react/lib/Button';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { DirectionalHint } from 'office-ui-fabric-react/lib/Callout';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { Dialog } from 'office-ui-fabric-react/lib/Dialog';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { CommandBar } from '@fluentui/react';

// local interfaces
interface IMenubarWebPartProps {}
interface IMenubarWebPartState {
    signingOut: boolean;
    dataButtons: IContextualMenuProps;
    scheduleButtons: IContextualMenuProps;
    currentUser: IBasicUserDataProps;
    messageBar?: {text: string; type: MessageBarType};
}

declare var window:any;
export default class Menubar extends React.Component<IMenubarWebPartProps, IMenubarWebPartState> {
    private authenticationService:AuthenticationService = new AuthenticationService();

    private defaultDataButtons:IContextualMenuProps = {
        items: [
            {
                key: 'kep',
                text: 'Data KEP',
                onRender: () => {
                    return <NavLink activeClassName={menubarStyles.activeMenu} to="/kep"><ActionButton iconProps={{iconName: "School"}}>Data KEP</ActionButton></NavLink>;
                }
            },
            {
                key: 'classes',
                text: 'Data Kelas',
                onRender: () => {
                    return <NavLink activeClassName={menubarStyles.activeMenu} to="/kelas"><ActionButton iconProps={{iconName: "GraduationCap"}}>Data Kelas</ActionButton></NavLink>;
                }
            },
            {
                key: 'modules',
                text: 'Data Modul',
                onRender: () => {
                    return <NavLink activeClassName={menubarStyles.activeMenu} to="/modul"><ActionButton iconProps={{iconName: "Modules"}}>Data Modul</ActionButton></NavLink>;
                }
            },
            {
                key: 'lessons',
                text: 'Data Pelajaran',
                onRender: () => {
                    return <NavLink activeClassName={menubarStyles.activeMenu} to="/pelajaran"><ActionButton iconProps={{iconName: "Book"}}>Data Pelajaran</ActionButton></NavLink>;
                }
            },
            {
                key: 'event',
                text: 'Data Acara Bersama',
                onRender: () => {
                    return <NavLink activeClassName={menubarStyles.activeMenu} to="/acara"><ActionButton iconProps={{iconName: "CalendarCheck"}}>Data Acara Bersama</ActionButton></NavLink>;
                }
            },
            {
                key: 'yearlyIntake',
                text: 'Data Tahun Pelajaran',
                onRender: () => {
                    return <NavLink activeClassName={menubarStyles.activeMenu} to="/tahunPelajaran"><ActionButton iconProps={{iconName: "Calendar"}}>Data Tahun Pelajaran</ActionButton></NavLink>;
                }
            },
            {
                key: 'teachers',
                text: 'Data Pengguna',
                onRender: () => {
                    return <NavLink activeClassName={menubarStyles.activeMenu} to="/pengguna"><ActionButton iconProps={{iconName: "User"}}>Data Pengguna</ActionButton></NavLink>;
                }
            }
        ]
    }

    private defaultScheduleButtons:IContextualMenuProps = {
        items: [
            {
                key: 'teacherSchedule',
                text: 'Jadwal Mengajar Guru',
                onRender: () => {
                    return <NavLink activeClassName={menubarStyles.activeMenu} to="/jadwalGuru"><ActionButton iconProps={{iconName: "Calendar"}}>Jadwal Mengajar Guru</ActionButton></NavLink>;
                }
            },
            {
                key: 'schedule',
                text: 'Jadwal KEP',
                onRender: () => {
                    return <NavLink activeClassName={menubarStyles.activeMenu} to="/jadwal"><ActionButton iconProps={{iconName: "Calendar"}}>Jadwal KEP</ActionButton></NavLink>;
                }
            },
            {
                key: 'randomise',
                text: 'Acak Jadwal',
                onRender: () => {
                    return <NavLink activeClassName={menubarStyles.activeMenu} to="/pengacakan"><ActionButton iconProps={{iconName: "Dice"}}>Acak Jadwal</ActionButton></NavLink>;
                }
            }
        ].filter((item) => {
            if (!GeneralService.isCurrentUserSecretary() && !GeneralService.isCurrentUserAdmin() && item.key === 'randomise') {
                return false;
            }
            return true;
        })
    }

    constructor(props: IMenubarWebPartProps) {
        super(props);
        this.state = {
            signingOut: false,
            dataButtons: this.defaultDataButtons,
            scheduleButtons: this.defaultScheduleButtons,
            currentUser: window.getCurrentUser()
        }
    }

    private _onSignOutClicked = async () => {
        try {
            this.setState({signingOut: true});
            await this.authenticationService.logout();
            window.location.href = "/logout";
        } catch(e) {
            this.setState({
                messageBar: {
                    text: `Gagal untuk keluar dari sistem. Error: ${e}. Harap coba beberapa saat lagi. Apabila masih terjadi masalah, hubungi administrator.`,
                    type: MessageBarType.error
                }
            })
        }
    }

    public render() {
        return (
            <Stack className={menubarStyles.menubar}>
                <Stack.Item className={menubarStyles.firstLayer}>
                    <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className={menubarStyles.container}>
                        <Stack.Item>
                            <Stack horizontal verticalAlign="center" tokens={{childrenGap: 10}}>
                                <Stack.Item>
                                    <img src={LogoDark} style={{height: 50}} />
                                </Stack.Item>
                                <Stack.Item className={menubarStyles.title}>
                                    <Text as="h1" variant="xLarge">SEP St. Yohanes Penginjil</Text>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <DefaultButton styles={{menuIcon: {color: "#fff"}}} className={menubarStyles.personaButton} menuProps={{
                                directionalHint: DirectionalHint.bottomRightEdge,
                                items: [
                                    {
                                        key: 'myaccount',
                                        text: 'Kelola Akun',
                                        iconProps: { iconName: 'User' },
                                        onClick: () => {window.location.href = "/akun"}
                                    },
                                    {
                                        key: 'signout',
                                        text: 'Keluar',
                                        iconProps: { iconName: 'SignOut' },
                                        onClick: () => {this._onSignOutClicked()}
                                    }
                                ]
                            }}>
                                <Persona
                                    text={this.state.currentUser.name}
                                    imageInitials={GeneralService.getInitial(this.state.currentUser.name)}
                                    size={PersonaSize.size40}
                                    hidePersonaDetails={false}
                                    styles={{primaryText: {color: '#fff !important'}}}
                                    imageAlt="Aldvin Tumbelaka"/>
                            </DefaultButton>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item className={menubarStyles.secondLayer}>
                    <Stack className={menubarStyles.container}>
                        <CommandBar 
                            styles={{root: {padding: 0}}}
                            items={[
                                {
                                    key: 'home',
                                    text: 'Beranda',
                                    iconProps: { iconName: 'Home' },
                                    onRender: (item) => {
                                        return <Stack verticalAlign={"center"}><NavLink activeClassName={menubarStyles.activeMenu} to="/beranda"><CommandButton iconProps={{iconName: "Home"}}>{item.text}</CommandButton></NavLink></Stack>;
                                    }
                                },
                                {
                                    key: 'data',
                                    text: 'Data',
                                    iconProps: { iconName: 'Database' },
                                    subMenuProps: this.state.dataButtons
                                },
                                {
                                    key: 'schedule',
                                    text: 'Penjadwalan',
                                    iconProps: { iconName: 'CalendarEmpty' },
                                    subMenuProps: this.state.scheduleButtons
                                }
                            ]}/>
                    </Stack>
                </Stack.Item>
                {
                    this.state.messageBar ? (
                        <Stack.Item>
                            <MessageBar messageBarType={this.state.messageBar.type} isMultiline={true} onDismiss={() => {this.setState({messageBar: undefined})}}>
                                {this.state.messageBar.text}
                            </MessageBar>
                        </Stack.Item>
                    ) : null
                }
                {
                    this.state.signingOut ? (
                        <Dialog hidden={false}>
                            <Spinner label="Harap tunggu ... " labelPosition="bottom" size={SpinnerSize.large}/>
                        </Dialog>
                    ) : null
                }
            </Stack>
        );
    }
}
