import React from 'react';
import moment from 'moment';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import ReactExport from 'react-data-export';
import kepInternalStyles from './../../styles/kepinternal.module.scss';

// import services
import RandomiseService from './../../services/randomise';
import ScheduleService from './../../services/schedule';
import LessonService from './../../services/lesson';
import GeneralService from './../../services/general';

// import components
import DownloadJSON from './../downloadJSON/downloadJSON';

// local interfaces
import { IKEPDataProps, IClassDataProps, IModuleDataProps, ILessonDataProps, IEventDataProps, IUserDataProps, ISchedulePreviewProps, IScheduleDataProps, IIntakeDataProps, ISchedulePreviewLessonProps, ISaveScheduleParameterProps } from '../../props/data';

// import fabric ui components
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { DetailsList, IColumn, DetailsRow, DetailsListLayoutMode, ConstrainMode, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ScrollablePane, ScrollbarVisibility } from 'office-ui-fabric-react/lib/ScrollablePane';
import { Sticky, StickyPositionType } from 'office-ui-fabric-react/lib/Sticky';
import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { NormalPeoplePicker } from 'office-ui-fabric-react/lib/Pickers';
import { Dialog } from 'office-ui-fabric-react/lib/Dialog';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { MessageBarType, MessageBar } from 'office-ui-fabric-react/lib/MessageBar';
import { getTheme, ITheme } from 'office-ui-fabric-react/lib/Styling';
import { Icon } from '@fluentui/react/lib/Icon';
import { CommandBarButton, PrimaryButton } from '@fluentui/react';

export interface ISelectedDataProps {
    KEP: IKEPDataProps;
    intake: IIntakeDataProps;
    startDate: string;
};
export interface IAllDataProps {
    KEP: IKEPDataProps[];
    classes: IClassDataProps[];
    modules: IModuleDataProps[];
    lessons: ILessonDataProps[];
    events: IEventDataProps[];
    teachers: IUserDataProps[];
    intakes: IIntakeDataProps[];
};
interface ITeacherSuggestionItem {
    teacher: IUserDataProps;
    available?: boolean;
    warning?: string;
    error?: string;
};
interface IScheduleListProps {
    data: IAllDataProps;
    selected: ISelectedDataProps;
    schedules: ISchedulePreviewProps[];
    allowDelete?: boolean;
    onSave?(): void;
    onDelete?(): void;
};
interface IScheduleListState {
    columns: IColumn[];
    selected: ISelectedDataProps;
    schedules: ISchedulePreviewProps[];
    fullScreenMode?: boolean;
    saving: boolean;
    deleting: boolean;
    randoming: boolean;
    downloadJSONPropsOpened?: boolean;
    randomingLoadingText?: string;
    messageBar?: {text: string; type: MessageBarType};
};

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default class ScheduleList extends React.Component<IScheduleListProps, IScheduleListState> {
    private scheduleService:ScheduleService = new ScheduleService();
    private theme:ITheme = getTheme();
    private randomiseService:RandomiseService;
    private lessonService:LessonService = new LessonService();
    private searchTeacherKey:string = "";
    private fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    private fileExtension = '.xlsx';

    private defaultColumns:IColumn[] = [
        {
            key: "date",
            fieldName: "date",
            name: "Tanggal",
            minWidth: 100,
            maxWidth: 100,
            onRender: (item?:any) => {
                let date = item && item.date ? moment(item.date).format("DD/MM/YYYY") : "";
                return <Text>{date}</Text>
            }
        }
    ];

    constructor(props: IScheduleListProps) {
        super(props);
        this.state = {
            saving: false,
            deleting: false,
            randoming: false,
            selected: this.props.selected,
            schedules: this.props.schedules,
            fullScreenMode: false,
            columns: []
        };
        this.randomiseService = new RandomiseService(
            {KEP: this.props.selected.KEP, intake: this.props.selected.intake, startDate: this.props.selected.startDate},
            {events: this.props.data.events, lessons: this.props.data.lessons, teachers: this.props.data.teachers, classes: this.props.data.classes, modules: this.props.data.modules}
        );
    }

    private csvData = [{
            columns: [
                {title: "Headings", width: {wpx: 80}},//pixels width 
                {title: "Text Style", width: {wch: 40}},//char width 
                {title: "Colors", width: {wpx: 90}},
            ],
            data: [
                [
                    {value: "H1", style: {font: {sz: "24", bold: true}}},
                    {value: "Bold", style: {font: {bold: true}}},
                    {value: "Red", style: {fill: {patternType: "solid", fgColor: {rgb: "FFFF0000"}}}},
                ],
                [
                    {value: "H2", style: {font: {sz: "18", bold: true}}},
                    {value: "underline", style: {font: {underline: true}}},
                    {value: "Blue", style: {fill: {patternType: "solid", fgColor: {rgb: "FF0000FF"}}}},
                ],
                [
                    {value: "H3", style: {font: {sz: "14", bold: true}}},
                    {value: "italic", style: {font: {italic: true}}},
                    {value: "Green", style: {fill: {patternType: "solid", fgColor: {rgb: "FF00FF00"}}}},
                ],
                [
                    {value: "H4", style: {font: {sz: "12", bold: true}}},
                    {value: "strike", style: {font: {strike: true}}},
                    {value: "Orange", style: {fill: {patternType: "solid", fgColor: {rgb: "FFF86B00"}}}},
                ],
                [
                    {value: "H5", style: {font: {sz: "10.5", bold: true}}},
                    {value: "outline", style: {font: {outline: true}}},
                    {value: "Yellow", style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFFF00"}}}},
                ],
                [
                    {value: "H6", style: {font: {sz: "7.5", bold: true}}},
                    {value: "shadow", style: {font: {shadow: true}}},
                    {value: "Light Blue", style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}}
                ]
            ]
        }];

    private _onSelectedTeacherChanged = (items?:any, lessonId?:string, scheduleIndex?:number) => {
        let schedules:ISchedulePreviewProps[] = JSON.parse(JSON.stringify(this.state.schedules));
        let {teachers} = this.props.data;
        if (scheduleIndex !== undefined && lessonId !== undefined) {
            let selectedSchedule = schedules[scheduleIndex];
            if (selectedSchedule && selectedSchedule.lessons) {
                let selectedLesson = selectedSchedule.lessons.find((lesson) => {return lesson.id === lessonId;});
                if (items && items[0] && selectedLesson) {
                    let selectedTeacher = teachers.find((teacher) => {return items && items[0] && items[0].teacher ? items[0].teacher.id === teacher.id : false;});
                    selectedLesson.teacher = selectedTeacher;
                    selectedLesson.errorMessage = undefined;
                    selectedLesson.rerandom = false;
                    selectedLesson.touched = true;
                } else if (selectedLesson) {
                    selectedLesson.teacher = undefined;
                    selectedLesson.rerandom = true;
                    selectedLesson.touched = true;
                }
            }
        }

        this.setState({schedules});
    }

    private _onScheduleNotesChanged(value?:string, lessonId?:string, scheduleIndex?:number) {
        let schedules:ISchedulePreviewProps[] = JSON.parse(JSON.stringify(this.state.schedules));
        if (scheduleIndex !== undefined && lessonId !== undefined) {
            let selectedSchedule = schedules[scheduleIndex];
            if (selectedSchedule && selectedSchedule.lessons) {
                let selectedLesson = selectedSchedule.lessons.find((lesson) => {return lesson.id === lessonId;});
                if (selectedLesson) {
                    selectedLesson.notes = value || "";
                    selectedLesson.touched = true;
                }
            }
        }

        this.setState({schedules});
    }

    private _onScheduleRerandomChanged(checked?:boolean, lessonId?:string, scheduleIndex?:number) {
        let schedules:ISchedulePreviewProps[] = JSON.parse(JSON.stringify(this.state.schedules));
        if (scheduleIndex !== undefined && lessonId !== undefined) {
            let selectedSchedule = schedules[scheduleIndex];
            if (selectedSchedule && selectedSchedule.lessons) {
                let selectedLesson = selectedSchedule.lessons.find((lesson) => {return lesson.id === lessonId;});
                if (selectedLesson) {
                    selectedLesson.rerandom = checked || false;
                }
            }
        }

        this.setState({schedules});
    }

    public componentDidMount() {
        let columns = this.defaultColumns;
        this.props.data.classes.forEach((c, idx) => {
            if (GeneralService.isCurrentUserSecretary() || GeneralService.isCurrentUserAdmin()) {
                columns.push({
                    key: `class_${c.name}_rerandom_${idx}`,
                    fieldName: `${c.id}_rerandom`,
                    name: "Acak",
                    minWidth: 45,
                    maxWidth: 45,
                    isMultiline: true,
                    onRender: (item?:any, scheduleIndex?:number) => {
                        if (item && item.event) {
                            return null;
                        } else if (item && item.lessons) {
                            let relatedLesson = item.lessons.find((cl:any) => {return cl.module.classId === c.id;});
                            return relatedLesson ? <Checkbox checked={relatedLesson.rerandom} onChange={(evt, checked) => {this._onScheduleRerandomChanged(checked, relatedLesson?.id, scheduleIndex)}}/> : null;
                        }
                        return null;
                    }
                });
            }
            columns.push({
                key: `class_${c.name}_chapter_${idx}`,
                fieldName: `${c.id}_chapter`,
                name: "Bab",
                minWidth: 45,
                maxWidth: 45,
                isMultiline: true,
                onRender: (item?:any) => {
                    if (item && item.event) {
                        return null;
                    } else if (item && item.lessons) {
                        let relatedLesson = item.lessons.find((cl:any) => {return cl.module.classId === c.id;});
                        if (relatedLesson) {
                            return <Text>{relatedLesson.chapter}</Text>;
                        }
                    }
                    return null;
                }
            });
            columns.push({
                key: `class_${c.name}_name_${idx}`,
                fieldName: `${c.id}_name`,
                name: c.name,
                minWidth: 200,
                maxWidth: 300,
                isMultiline: true,
                onRender: (item?:any) => {
                    if (item &&item.event) {
                        return <Text style={{color: this.theme.semanticColors.errorText}}>{item.event.name}</Text>;
                    } else if (item && item.lessons) {
                        let relatedLesson = item.lessons.find((cl:any) => {return cl.module.classId === c.id;});
                        if (relatedLesson) {
                            return <Text>{relatedLesson.name}</Text>;
                        }
                    }
                    return null;
                }
            });
            columns.push({
                key: `class_${c.name}_teacher_${idx}`,
                fieldName: `${c.id}_teacher`,
                name: "Guru",
                minWidth: 200,
                maxWidth: 300,
                isMultiline: true,
                onRender: (item?:any, scheduleIndex?:number) => {
                    if (item && item.event) {
                        return null;
                    } else if (item && item.lessons) {
                        let relatedLesson:ISchedulePreviewLessonProps | undefined = item.lessons.find((cl:any) => {return cl.module.classId === c.id;});
                        if (relatedLesson && (GeneralService.isCurrentUserSecretary() || GeneralService.isCurrentUserAdmin())) {
                            return (
                                <Stack.Item>
                                    <NormalPeoplePicker
                                        selectedItems={relatedLesson.teacher ? [{
                                            id: relatedLesson.teacher.id,
                                            text: relatedLesson.teacher.name
                                        }] : []}
                                        removeButtonAriaLabel="Hapus"
                                        onResolveSuggestions={async (key:any) => {return await this._onSearchTeacher(key, item, relatedLesson) as any}}
                                        pickerSuggestionsProps={{
                                            suggestionsHeaderText: 'Hasil pencarian guru (5 guru teratas)',
                                            noResultsFoundText: 'Guru tidak ditemukan',
                                            loadingText: 'Mencari guru ...'
                                        }}
                                        onChange={(items:any) => {this._onSelectedTeacherChanged(items, relatedLesson?.id, scheduleIndex)}}
                                        onRenderSuggestionsItem={(props:any) => {return this._onRenderTeacherSuggestion(props)}}
                                        itemLimit={1}/>
                                    {relatedLesson.warningMessage ? <Stack.Item>
                                        <Text variant={"small"} className={kepInternalStyles.warningText}>
                                            <Icon iconName={"Warning"} styles={{root: {marginRight: 5}}}/>{relatedLesson.warningMessage}
                                        </Text>
                                    </Stack.Item> : null}
                                    {relatedLesson.errorMessage ? <Stack.Item>
                                        <Text variant={"small"} className={kepInternalStyles.errorText}>
                                            <Icon iconName={"Error"} styles={{root: {marginRight: 5}}}/>{relatedLesson.errorMessage}
                                        </Text>
                                    </Stack.Item> : null}
                                </Stack.Item>
                            );
                        } else if (relatedLesson && relatedLesson.teacher) {
                            return <Persona imageInitials={GeneralService.getInitial(relatedLesson.teacher.name)} text={relatedLesson.teacher.name} size={PersonaSize.size24} />;
                        } else {
                            return null;
                        }
                    }

                    return null;
                }
            });
            columns.push({
                key: `class_${c.name}_notes_${idx}`,
                fieldName: `${c.id}_notes`,
                name: "Keterangan",
                isMultiline: true,
                minWidth: 200,
                maxWidth: 400,
                onRender: (item?:any, scheduleIndex?:number) => {
                    if (item && item.event) {
                        return null;
                    } else if (item && item.lessons) {
                        let relatedLesson:ISchedulePreviewLessonProps | undefined = item.lessons.find((cl:any) => {return cl.module.classId === c.id;});
                        if (relatedLesson && (GeneralService.isCurrentUserSecretary() || GeneralService.isCurrentUserAdmin())) {
                            return <TextField rows={1} resizable={false} multiline onChange={(evt, value) => this._onScheduleNotesChanged(value, relatedLesson?.id, scheduleIndex)} autoAdjustHeight styles={{fieldGroup: {minHeight: 0}}} value={relatedLesson.notes || ""}/>;
                        } else if (relatedLesson) {
                            return <Text>{relatedLesson.notes || "-"}</Text>;
                        } else {
                            return null;
                        }
                    }

                    return null;
                }
            });
        });

        this.randomiseService.setSelected(this.props.selected);
        this.setState({
            columns,
            schedules: this.props.schedules,
            selected: this.props.selected
        })
    }

    public componentWillReceiveProps(props:IScheduleListProps) {
        this.randomiseService.setSelected(props.selected);
        this.setState({
            schedules: props.schedules,
            selected: props.selected
        });
    }

    private _onRenderTeacherSuggestion = (props:ITeacherSuggestionItem):JSX.Element => {
        return (
            <Stack styles={{root: {padding: 10}}} horizontal horizontalAlign={"baseline"} verticalAlign={"center"} tokens={{childrenGap: 10}}>
                <Stack horizontalAlign={"baseline"}>
                    <Persona imageInitials={GeneralService.getInitial(props.teacher.name)} hidePersonaDetails size={PersonaSize.size24} />
                </Stack>
                <Stack grow={1} horizontalAlign={"baseline"}>
                    <Stack.Item><Text variant="medium" style={{fontWeight: 600}}>{props.teacher.name}</Text></Stack.Item>
                    {
                        !props.error && props.warning ? (
                            <Stack.Item><Text variant="small" style={{color: "#a20000"}}><Icon iconName="Error"/> {props.warning}</Text></Stack.Item>
                        ) : null
                    }
                    {
                        !props.error && props.available ? (
                            <Stack.Item><Text variant="small" style={{color: "#4f8a22"}}><Icon iconName="StatusCircleCheckmark"/> Jadwal tersedia</Text></Stack.Item>
                        ) : null
                    }
                    {
                        !props.error && !props.available ? (
                            <Stack.Item><Text variant="small" style={{color: "#a20000"}}><Icon iconName="Error"/> Jadwal guru sudah penuh</Text></Stack.Item>
                        ) : null
                    }
                    {
                        props.error ? (
                            <Stack.Item><Text variant="small" style={{color: "#a20000"}}><Icon iconName="Error"/> {props.error}</Text></Stack.Item>
                        ) : null
                    }
                </Stack>
            </Stack>
        );
    }

    private _onSearchTeacher = async (key:string, schedule?:ISchedulePreviewProps, lesson?:ISchedulePreviewLessonProps):Promise<ITeacherSuggestionItem[]> => {
        let {teachers} = this.props.data;
        this.searchTeacherKey = key;
        await new Promise((resolve) => {setTimeout(() => {resolve()}, 1500)});

        if (lesson && schedule && key.length > 0 && this.searchTeacherKey === key) { 
            let results = teachers.filter((teacher) => {return teacher.name.toLowerCase().indexOf(key.toLowerCase()) > -1 || teacher.city.toLowerCase().indexOf(key.toLowerCase()) > -1;});
            let relatedTeachers = await this.lessonService.getTeachersByLessonId(lesson.id);
            let availableTeachers = await Promise.all(results.filter((res) => {
                return relatedTeachers.findIndex((teacher) => {
                    return teacher.id === res.id;
                }) > -1;
            }).filter((res, idx:number) => {
                return idx < 5;
            }).map(async (res) => {
                try {
                    // check teacher schedules
                    let startWeek:string = GeneralService.getStartOfWeek(schedule.date);
                    let endWeek:string = moment(startWeek).add(1, 'weeks').toISOString();
                    let teacherSchedules = await this.scheduleService.getRangeByTeacherId(res.id, startWeek, endWeek);
                    
                    let available:boolean = true;
                    if (teacherSchedules) {
                        let totalSchedulesInWeek:number = teacherSchedules.filter((schedule) => {
                            return schedule.date >= startWeek && schedule.date <= endWeek;
                        }).length;
                        if (res.maxLessonsPerWeek && totalSchedulesInWeek >= res.maxLessonsPerWeek) {available = false;}
                    }

                    // check if teacher capabl of teaching this kep
                    let KEPIncluded:boolean = res.includedKEPId ? res.includedKEPId.indexOf(this.state.selected.KEP.id) > -1 : false;

                    return {
                        teacher: res,
                        available,
                        warning: !KEPIncluded ? "Guru tidak dapat mengajar di KEP ini" : undefined
                    };
                } catch(e) {
                    return {
                        teacher: res,
                        error: "Error saat mengambil jadwal guru"
                    };
                }
            }));
            return availableTeachers;
        } else {return []}
    }

    private _onRenderRow = (props:any) => {
        let {schedules} = this.state;
        if (schedules) {
            let relatedSchedule = schedules[props.itemIndex];
            if (relatedSchedule && relatedSchedule.event && relatedSchedule.event.type === 'Libur') {
                return <DetailsRow {...props} styles={{root: {background: this.theme.semanticColors.errorBackground}}} />
            } else if (relatedSchedule && relatedSchedule.event) {
                return <DetailsRow {...props} styles={{root: {background: this.theme.semanticColors.warningBackground}}} />
            }
        }

        return <DetailsRow {...props} />
    }

    private _onRandomTeachersSchedule = async () => {
        try {
            this.setState({randoming: true});
            let schedules = JSON.parse(JSON.stringify(this.state.schedules)); 
            schedules = await this.randomiseService.randomise(schedules, this._onUpdateLoadingText);
            this.setState({schedules, randoming: false});
        } catch(e) {
            this.setState({
                randoming: false,
                messageBar: {text: "Maaf, sistem mengalamin masalah saat mengacak jadwal guru. Silahkan coba beberapa saat lagi.", type: MessageBarType.error}
            })
        }
    }

    private _onSave = async () => {
        let {schedules, selected} = this.state;
        // generate schedule data
        if (schedules && selected) {
            this.setState({saving: true});
            let data:IScheduleDataProps[] = this.getScheduleData();
            await this.scheduleService.save(data);
            if (this.props.onSave) {
                this.props.onSave();
                this.setState({saving: false});
            } else {
                this.setState({
                    saving: false,
                    messageBar: {
                        text: "Semua jadwal berhasil disimpan.",
                        type: MessageBarType.success
                    }
                });
            }
        } 
    }

    private getScheduleData() {
        let {schedules, selected} = this.state;
        let data:ISaveScheduleParameterProps[] = [];
        schedules.forEach((sch) => {
            let props = {
                id: "", // to be generated by the service
                date: sch.date,
                intakeId: selected.intake.id
            }
            if (sch.event) {
                data.push({
                    ...props,
                    type: "event",
                    eventId: sch.event.id,
                    event: sch.event
                });
            } else if (sch.lessons) {
                sch.lessons.forEach((lesson) => {
                    data.push({
                        ...props,
                        type: "lesson",
                        lessonId: lesson.id,
                        KEPId: selected.KEP ? selected.KEP.id : undefined,
                        teacherId: lesson.teacher ? lesson.teacher.id : undefined,
                        teacher: lesson.teacher ? lesson.teacher : undefined,
                        lesson: lesson,
                        touched: lesson.touched,
                        id: lesson.scheduleId || ""
                    });
                });
            }
        });
        return data;
    }

    private _onDelete = async () => {
        let {selected} = this.state;
        try {
            if (selected.KEP && selected.intake && window.confirm(`Apakah anda yakin untuk menghapus semua jadwal yang ada di KEP ${selected.KEP.name} pada tahun pelajaran ${selected.intake.name}?`)) {
                this.setState({deleting: true});
                await this.scheduleService.deleteByKEPId(selected.KEP.id, selected.intake.id);
                if (this.props.onDelete) {
                    this.props.onDelete();
                    this.setState({deleting: false});
                } else {
                    this.setState({
                        deleting: false,
                        messageBar: {
                            text: `Semua pelajaran di KEP ${selected.KEP.name} pada tahun pelajaran ${selected.intake.name} berhasil dihapus.`,
                            type: MessageBarType.success
                        }
                    });
                }
            }
        } catch(e) {
            this.setState({
                deleting: false,
                messageBar: {
                    text: "Gagal menghapus semua jadwal. Harap coba beberapa saat lagi. Pesan: " + e.toString(),
                    type: MessageBarType.error
                }
            });
        }
    }

    private getCSVData() {
        const {schedules} = this.state;
        const {classes} = this.props.data;
        const romanNumber = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII', 'XIII', 'XIIV', 'XV', 'XVI', 'XVII', 'XVIII', 'XVIX', 'XX', 'XXI', 'XXII', 'XXIII', 'XXIV', 'XXV', 'XXVI', 'XVII', 'XVIII', 'XVIX', 'XXX'];

        let columns:{title: string, width: {wch?: number, wpx?: number}, classId?: string}[] = [
            {title: "TANGGAL", width: {wch: 12}},
            {title: "NO", width: {wch: 3}}
        ];

        classes.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0)).forEach((cl) => {
            columns.push({title: 'BAB', width: {wch: 4}, classId: cl.id});
            columns.push({title: 'JUDUL', width: {wpx: 250}, classId: cl.id});
            columns.push({title: 'GURU', width: {wpx: 150}, classId: cl.id});
            columns.push({title: 'KETERANGAN', width: {wpx: 200}, classId: cl.id});
        });

        let data:any = [];
        let ctr = 1;
        schedules.map((schedule) => {
            let scheduleData:any = [];
            // push schedule date
            scheduleData.push({value: moment(schedule.date).format('DD/MM/YYYY')});

            if (schedule.event) {
                let bgColor = 'fde7e9';
                const eventName:string = schedule.event.name;
                scheduleData.push({value: "-"}); // push empty number
                if (schedule.event.type !== 'Libur') {
                    bgColor = "fff4ce";
                }
                
                classes.forEach((cl) => {
                    scheduleData.push({value: ""}); // push empty chapter
                    scheduleData.push({value: eventName}); // push event name
                    scheduleData.push({value: ""}); // push empty teacher
                    scheduleData.push({value: ""}); // push empty notes
                });

                scheduleData = scheduleData.map((schd:any) => {
                    schd.style = {fill: {patternType: "solid", fgColor: {rgb: bgColor}}, font: {bold: true}};
                    return schd;
                });
            } else if (schedule.lessons) {
                scheduleData.push({value: ctr + ""}); // push empty number

                classes.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0)).forEach((cl) => {
                    let lesson = (schedule.lessons || []).find((lesson) => {
                        return lesson.module && lesson.module.classId === cl.id;
                    });
                    
                    scheduleData.push({value: lesson ? romanNumber[lesson.chapter - 1] : ""}); // chapter
                    scheduleData.push({value: lesson ? lesson.name : ""}); // push lesson name
                    scheduleData.push({value: lesson && lesson.teacher ? lesson.teacher.name : ""}); // push teacher name if applicable
                    scheduleData.push({value: lesson ? lesson.notes : ""}); // push notes
                });
                ctr++;
            }
            data.push(scheduleData);
        });

        return [{
            columns,
            data
        }];
    }

    private renderCommandBar() {
        let items:ICommandBarItemProps[] = [
            {key: "randomise", text: "Acak jadwal guru", iconProps:{iconName:"Dice"}, onClick: () => {this._onRandomTeachersSchedule()}},
            {key: "save", text: "Simpan jadwal", iconProps:{iconName:"Save"}, onClick: () => {this._onSave()}},
            {key: "exportToExcel", text: "Download (excel)", iconProps:{iconName:"Download"}, onRender: () => {
                return <ExcelFile element={<CommandBarButton iconProps={{iconName: "Download"}} text={"Download (Excel)"} styles={{root: {height: '100%'}}}/>} filename={`Jadwal ${this.props.selected.KEP.name} - ${this.props.selected.intake.name}`}>
                    <ExcelSheet dataSet={this.getCSVData()} name="Organization" />
                </ExcelFile>;
            }}
            //{key: "downloadJSON", text: "Download JSON (Dev only)", iconProps:{iconName:"Download"}, onClick: () => {this.setState({downloadJSONPropsOpened: true})}}
        ].filter((item) => {
            if (GeneralService.isCurrentUserSecretary() || GeneralService.isCurrentUserAdmin()) {
                return true;
            }
            return false;
        });
        if (this.state.fullScreenMode) {
            items.push({key: "enableFullScreen", text: "Keluar dari mode layar penuh", iconProps:{iconName:"CompressAlt"}, onClick: () => {this.setState({fullScreenMode: false})}});
        } else {
            items.push({key: "enableFullScreen", text: "Mode layar penuh", iconProps:{iconName:"Compress"}, onClick: () => {this.setState({fullScreenMode: true})}});
        }

        let farItems:ICommandBarItemProps[] = [];
        if (this.props.allowDelete) {
            farItems.push({key: "delete", text: "Hapus Jadwal", iconProps:{iconName:"Delete"}, onClick: () => {this._onDelete()}});
        }

        return (
            <CommandBar
                items={items}
                farItems={farItems}
                styles={{root: {padding: 0}}}
                ariaLabel="Gunakan panah kiri dan kanan untuk berpindah tombol"/>
        );
    }

    private renderLoadingDialog(text:string) {
        return (
            <Dialog isBlocking={true} hidden={false}>
                <Spinner size={SpinnerSize.large} label={text} />
            </Dialog>
        );
    }

    private _onUpdateLoadingText = (text:string) => {
        this.setState({randomingLoadingText: text})
    }

    public render() {
        return (
            <Stack>
                {
                    this.state.schedules && this.state.fullScreenMode ? (
                        <Stack>
                            <Stack.Item styles={{root: {position: "fixed", top: 0, left: 0, right: 0}}}>
                                {this.renderCommandBar()}
                            </Stack.Item>
                            {
                                this.state.messageBar ? (
                                    <Stack.Item>
                                        <MessageBar messageBarType={this.state.messageBar.type} isMultiline={true} onDismiss={() => {this.setState({messageBar: undefined})}}>
                                            {this.state.messageBar.text}
                                        </MessageBar>
                                    </Stack.Item>
                                ) : null
                            }
                            <Stack.Item>
                                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={{root: {top: 44, background: '#fff'}}}>
                                    <DetailsList 
                                        items={this.state.schedules || []} 
                                        columns={this.state.columns} 
                                        isHeaderVisible={true}
                                        onRenderRow={this._onRenderRow}
                                        constrainMode={ConstrainMode.unconstrained}
                                        layoutMode={DetailsListLayoutMode.fixedColumns}
                                        selectionMode={SelectionMode.none}
                                        onRenderDetailsHeader={(headerProps, defaultRender) => {
                                            return (
                                                <Sticky
                                                    stickyPosition={StickyPositionType.Header}
                                                    isScrollSynced={true}
                                                    stickyBackgroundColor="transparent">
                                                        <Text>{defaultRender ? defaultRender(headerProps) : ""}</Text>
                                                </Sticky>
                                            );
                                        }}/>
                                </ScrollablePane>
                            </Stack.Item>
                        </Stack>
                    ) : null
                }
                {
                    this.state.schedules && !this.state.fullScreenMode ? (
                        <Stack>
                            <Stack.Item>
                                {this.renderCommandBar()}
                            </Stack.Item>
                            {
                                this.state.messageBar ? (
                                    <Stack.Item>
                                        <MessageBar messageBarType={this.state.messageBar.type} isMultiline={true} onDismiss={() => {this.setState({messageBar: undefined})}}>
                                            {this.state.messageBar.text}
                                        </MessageBar>
                                    </Stack.Item>
                                ) : null
                            }
                            <Stack.Item>
                                <DetailsList 
                                    items={this.state.schedules || []} 
                                    columns={this.state.columns} 
                                    isHeaderVisible={true}
                                    selectionMode={SelectionMode.none}
                                    onRenderRow={this._onRenderRow} />
                            </Stack.Item>
                        </Stack>
                    ) : null
                }
                { this.state.randoming ? this.renderLoadingDialog(this.state.randomingLoadingText || "Mengacak jadwal guru. Proses ini akan memakan waktu 2-5 menit ...") : null }
                { this.state.saving ? this.renderLoadingDialog("Menyimpan jadwal KEP ...") : null }
                { this.state.deleting ? this.renderLoadingDialog("Mengahpus semua jadwal KEP ...") : null }
            </Stack>
        );
    }
}
