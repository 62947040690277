import React from 'react';

// import components
import ClassList from './../../components/class/classList';

// import fabric ui
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { IColumn } from 'office-ui-fabric-react/lib/DetailsList';

// local interfaces
interface IClassPageProps {}
interface IClassPageState {}

export default class ClassPage extends React.Component<IClassPageProps, IClassPageState> {
    private listColumns:IColumn[] = [
        {
            key: 'className',
            name: 'Nama Kelas',
            fieldName: 'name',
            minWidth: 100,
            data: 'string',
            isPadded: true
        }
    ]

    constructor(props: IClassPageProps) {
        super(props);
    }

    public render() {
        return (
            <Stack styles={{root: {padding: 20}}}>
                <Stack.Item>
                    <h2>Kelas</h2>
                </Stack.Item>
                <Stack.Item>
                    <ClassList listColumns={this.listColumns} includeCommandBar={true} hasActionButton={true} searchBy={["name"]} sortable={["name"]} filterable={["name"]}/>
                </Stack.Item>
            </Stack>
        );
    }
}
