import React from 'react';

// import interfaces
import {IFormFieldProps} from './../formProps';

// import subcomponents
import FieldHeader from './../sub/fieldHeader';
import FieldFooter from './../sub/fieldFooter';

// import services
import Validation from './../services/validation';

// import fabric ui components
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { SpinButton } from 'office-ui-fabric-react/lib/SpinButton';

interface IFESpinButtonState {
    field: IFormFieldProps;
    fields: IFormFieldProps[];
}

interface IFESpinButtonProps extends IFESpinButtonState {
    onValueChanged(key:string, value:string, errorMessage?:string): void;
}

export default class FESpinButton extends React.Component<IFESpinButtonProps, IFESpinButtonState> {
    constructor(props:IFESpinButtonProps) {
        super(props);

        let {field, fields} = this.props;
        this.state = {
            field,
            fields
        }
    }

    public componentWillReceiveProps(props:IFESpinButtonProps) {
        this.setState({field: props.field});
    }

    private _onSpinButtonChanged = (addition:number, value?:string) => {
        let {field, fields} = this.state;
        let number = value ? parseInt(value) : 0;
        number = number + addition;
        value = number + ""
        let error = Validation.field(field, value || "", fields);
        this.props.onValueChanged(field.key, value || "", error);
    }

    public render() {
        let {field} = this.state;
        return (
            <Stack tokens={{childrenGap: 5}}>
                <Stack.Item>
                    <FieldHeader title={field.title} required={field.isRequired} content={field.content}/>
                </Stack.Item>
                <Stack.Item>
                    <SpinButton step={field.spintButtonConfiguration ? field.spintButtonConfiguration.step : undefined}
                        min={field.spintButtonConfiguration ? field.spintButtonConfiguration.min : undefined}
                        max={field.spintButtonConfiguration ? field.spintButtonConfiguration.max : undefined}
                        value={field.value}
                        onIncrement={(v) => this._onSpinButtonChanged(1, v)}
                        onDecrement={(v) => this._onSpinButtonChanged(-1, v)}/>
                </Stack.Item>
                <Stack.Item>
                    <FieldFooter error={field.errorMessage} description={field.description}/>
                </Stack.Item>
            </Stack>
        );
    }
}