import React from 'react';

// import components
import ModuleList from './../../components/module/moduleList';

// import fabric ui
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { IColumn } from 'office-ui-fabric-react/lib/DetailsList';

// local interfaces
interface IModulePageProps {}
interface IModulePageState {}

export default class ModulePage extends React.Component<IModulePageProps, IModulePageState> {
    private listColumns:IColumn[] = [
        {
            key: 'moduleName',
            name: 'Nama Modul',
            fieldName: 'name',
            minWidth: 100,
            data: 'string',
            isPadded: true
        },
        {
            key: 'className',
            name: 'Kelas',
            minWidth: 100,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Text>{item.class.name}</Text>;
            }
        }
    ]

    constructor(props: IModulePageProps) {
        super(props);
    }

    public render() {
        return (
            <Stack styles={{root: {padding: 20}}}>
                <Stack.Item>
                    <h2>Modul</h2>
                </Stack.Item>
                <Stack.Item>
                    <ModuleList listColumns={this.listColumns} includeCommandBar={true} hasActionButton={true} searchBy={["name"]} sortable={["name"]} filterable={["name"]} />
                </Stack.Item>
            </Stack>
        );
    }
}
