import React from 'react';

// import interfaces
import {IFormFieldProps} from './../formProps';

// import subcomponents
import FieldHeader from './../sub/fieldHeader';
import FieldFooter from './../sub/fieldFooter';

// import services
import Validation from './../services/validation';

// import fabric ui components
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';

interface IFERadioButtonState {
    field: IFormFieldProps;
    fields: IFormFieldProps[];
}

interface IFERadioButtonProps extends IFERadioButtonState {
    onValueChanged(key:string, value?:string | number, errorMessage?:string): void;
}

export default class FERadioButton extends React.Component<IFERadioButtonProps, IFERadioButtonState> {
    constructor(props:IFERadioButtonProps) {
        super(props);

        let {field, fields} = this.props;
        this.state = {
            field,
            fields
        }
    }

    public componentWillReceiveProps(props:IFERadioButtonProps) {
        this.setState({field: props.field});
    }

    private _onRadioButtonChanged = (evt?:any, options?:IChoiceGroupOption) => {
        let {field, fields} = this.state;
        let error = Validation.field(field, options ? options.key : undefined, fields);
        this.props.onValueChanged(field.key, options ? options.key : undefined, error);
    }

    public render() {
        let {field} = this.state;
        let options:IChoiceGroupOption[] = [];
        if (field.choicesConfiguration) {
            let choices = field.choicesConfiguration.choices || [];
            options = choices.map((choice) => {
                return {key: choice.key, text: choice.text};
            });
        }

        return (
            <Stack tokens={{childrenGap: 5}}>
                <Stack.Item>
                    <FieldHeader title={field.title} required={field.isRequired} content={field.content}/>
                </Stack.Item>
                <Stack.Item>
                    <ChoiceGroup disabled={field.isDisabled} selectedKey={field.value} options={options} onChange={this._onRadioButtonChanged} />
                </Stack.Item>
                <Stack.Item>
                    <FieldFooter error={field.errorMessage} description={field.description}/>
                </Stack.Item>
            </Stack>
        );
    }
}