import React from 'react';
import moment from 'moment';

// import components
import TeacherScheduleList from './../../components/schedule/teacherScheduleList';

// import interfaces
import { IClassDataProps, IKEPDataProps, ILessonDataProps, IModuleDataProps, IScheduleDataProps, IUserDataProps } from '../../props/data';

// import services
import UserService from './../../services/user';
import LessonService from '../../services/lesson';
import KEPService from '../../services/kep';

// import fabric ui
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { DatePicker } from 'office-ui-fabric-react/lib/DatePicker';
import { NormalPeoplePicker  } from 'office-ui-fabric-react/lib/Pickers';
import { IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { IPersonaProps } from '@fluentui/react';
import { MessageBar, MessageBarType  } from 'office-ui-fabric-react/lib/MessageBar';

// local interfaces
interface ITeacherScheduleProps {
    data: IScheduleDataProps;
    date: string;
    lesson: ILessonDataProps;
    module?: IModuleDataProps;
    class?: IClassDataProps;
    KEP: IKEPDataProps;
}
interface ISchedulePageProps {}
interface ISchedulePageState {
    loaded?: boolean;
    loadingSchedule: boolean;
    data: {
        teachers: IUserDataProps[];
        lessons: ILessonDataProps[];
        KEP: IKEPDataProps[];
    };
    schedules?: ITeacherScheduleProps[];
    selected: {
        teacher?: string;
        startDate?: string;
        endDate?: string;
    }
}

export default class SchedulePage extends React.Component<ISchedulePageProps, ISchedulePageState> {
    private userService:UserService = new UserService();
    private lessonService:LessonService = new LessonService();
    private kepService:KEPService = new KEPService();

    constructor(props: ISchedulePageProps) {
        super(props);

        this.state = {
            loadingSchedule: false,
            data: {
                teachers: [],
                KEP: [],
                lessons: []
            },
            selected: {
                startDate: moment().toISOString(),
                endDate: moment().add(1, 'months').toISOString()
            }
        }
    }

    public async componentDidMount() {
        let teachers = await this.userService.getAll("Guru");
        let lessons = await this.lessonService.getAll();
        let KEP = await this.kepService.getAll();
        let currentUser = await this.userService.getCurrentUser();

        this.setState({
            data: {KEP, teachers, lessons},
            loaded: true,
            selected: {
                teacher: currentUser.roles.indexOf("Guru") > -1 ? currentUser.id : undefined,
                startDate: moment().toISOString(),
                endDate: moment().add(1, 'months').toISOString()
            }
        });
    }

    private _onSelectedTeacherChanged = (selectedItems?:IPersonaProps[]) => {
        let {selected} = this.state;
        selected.teacher = selectedItems && selectedItems[0] ? selectedItems[0].id : undefined;
        this.setState({selected});
    }

    private _onStartDateSelected = (date:null | undefined | Date) => {
        let {selected} = this.state;
        selected.startDate = date ? moment(date).toISOString() : "";
        this.setState({selected});
    }

    private _onEndDateSelected = (date:null | undefined | Date) => {
        let {selected} = this.state;
        selected.endDate = date ? moment(date).toISOString() : "";
        this.setState({selected});
    }

    private _onFormatDate(date:Date | undefined) {
        return date ? moment(date).format("DD/MM/YYYY") : "";
    }
    private _onSearchTeacher = (key:string):IPersonaProps[] => {
        let teachers = this.state.data.teachers;
        if (key.length > 0) {
            teachers = this.state.data.teachers.filter((teacher) => {
                return teacher.name.toLowerCase().indexOf(key.toLowerCase()) > -1 || teacher.name.toLowerCase().indexOf(key.toLowerCase()) > -1
            })
        }

        return teachers.map((teacher) => {
            return {
                id: teacher.id,
                text: teacher.name,
                secondaryText: teacher.email
            };
        });
    }

    private _onEmptySuggestions = (items?:IPersonaProps[]) => {
        return this.state.data.teachers.map((teacher) => {
            return {
                id: teacher.id,
                text: teacher.name,
                secondaryText: teacher.email
            };
        });
    }

    public render() {
        let {selected} = this.state;
        let selectedTeacherData = this.state.data.teachers.find((teacher) => {return selected.teacher ? selected.teacher === teacher.id : false;});

        return (
            <Stack styles={{root: {padding: 20}}} tokens={{childrenGap: 15}}>
                <Stack.Item>
                    <h2>Jadwal Mengajar Guru</h2>
                </Stack.Item>
                {
                    !this.state.loaded ? (
                        <Spinner label="Mempersiapkan halaman ..." labelPosition="bottom" size={SpinnerSize.large}/>
                    ) : null
                }
                {
                    this.state.loaded ? (
                        <Stack horizontal tokens={{childrenGap: 10}}>
                            <Stack.Item grow={1}>
                                <Label required={true}>Pilih Guru</Label>
                                <NormalPeoplePicker
                                        selectedItems={selectedTeacherData ? [{
                                            id: selectedTeacherData.id,
                                            text: selectedTeacherData.name
                                        }] : []}
                                        removeButtonAriaLabel="Hapus"
                                        onResolveSuggestions={this._onSearchTeacher}
                                        pickerSuggestionsProps={{
                                            suggestionsHeaderText: 'Hasil pencarian guru',
                                            noResultsFoundText: 'Guru tidak ditemukan',
                                            loadingText: 'Mencari guru ...'
                                        }}
                                        onEmptyResolveSuggestions={this._onEmptySuggestions}
                                        onChange={this._onSelectedTeacherChanged}
                                        itemLimit={1}/>
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <DatePicker
                                    disabled={this.state.loadingSchedule}
                                    label={"Tanggal mulai"}
                                    formatDate={this._onFormatDate}
                                    onSelectDate={this._onStartDateSelected}
                                    value={this.state.selected.startDate ? moment(this.state.selected.startDate).toDate() : undefined}
                                    placeholder="Pilih tanggal..."
                                    ariaLabel="Pilih tanggal"/>
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <DatePicker
                                    disabled={this.state.loadingSchedule}
                                    label={"Tanggal selesai"}
                                    formatDate={this._onFormatDate}
                                    onSelectDate={this._onEndDateSelected}
                                    minDate={this.state.selected.startDate ? moment(this.state.selected.startDate).toDate() : undefined}
                                    value={this.state.selected.endDate ? moment(this.state.selected.endDate).toDate() : undefined}
                                    placeholder="Pilih tanggal..."
                                    ariaLabel="Pilih tanggal"/>
                            </Stack.Item>
                        </Stack>
                    ) : null
                }
                {
                    selected.startDate && selected.endDate && selected.startDate > selected.endDate ? (
                        <Stack.Item>
                            <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                                <Stack verticalAlign={"center"} styles={{root: {height: '100%'}}}>
                                    <Text>Tanggal mulai tidak boleh melebihi tanggal selesai</Text>
                                </Stack>
                            </MessageBar>
                        </Stack.Item>
                    ) : null
                }
                {
                    selectedTeacherData && selected.startDate && selected.endDate && selected.startDate < selected.endDate ? (
                        <TeacherScheduleList data={this.state.data} startDate={selected.startDate} endDate={selected.endDate} teacher={selectedTeacherData}/>
                    ) : null
                }
            </Stack>
        );
    }
}
