import { v4 as uuid } from 'uuid';
import Request from './request';

// import interfaces
import { IEventDataProps } from './../props/data';

// import sample data
import event from './../sample/event';

export default class EventService {
    public async getById(id:string):Promise<IEventDataProps | undefined> {
        try {
            let results:any = await Request.get(`/api/kep-event/${id}`);
            let relatedEvent:IEventDataProps = results.data.data;
            return relatedEvent;
        } catch(e) {
            throw(e);
        }
    }

    public async getAll():Promise<IEventDataProps[]> {
        try {
            let results:any = await Request.get("/api/kep-event");
            let events:IEventDataProps[] = results.data.data;
            return events;
        } catch(e) {
            throw(e);
        }
    }

    public async create(data:IEventDataProps):Promise<IEventDataProps> {
        try {
            data.id = uuid();
            let result:any = await Request.post("/api/kep-event", data);
            return result.data.data as IEventDataProps;
        } catch(e) {
            throw(e);
        }
    }

    public async update(id:string, data:IEventDataProps) {
        try {
            delete data.id;
            await Request.patch(`/api/kep-event/${id}`, data);
            return data;
        } catch(e) {
            throw(e);
        }
    }

    public async delete(data:IEventDataProps) {
        try {
            await Request.delete(`/api/kep-event/${data.id}`);
            return data;
        } catch(e) {
            throw(e);
        }
    }

    /*
    public async getAll():Promise<IEventDataProps[]> {
        await new Promise((resolve) => {setTimeout(() => {return resolve();}, 2000);});
        return event;
    }

    public async create(data:IEventDataProps) {
        await new Promise((resolve) => {setTimeout(() => {return resolve();}, 2000);});
        data.id = uuid();
        event.push(data);
        return data;
    }

    public async update(id:string, data:IEventDataProps) {
        await new Promise((resolve) => {setTimeout(() => {return resolve();}, 2000);});
        event.forEach((d) => {
            if (d.id === id) {d = data;}
        });
        return data;
    }

    public async delete(data:IEventDataProps[]) {
        await new Promise((resolve) => {setTimeout(() => {return resolve();}, 2000);});
        let deletedIds = data.map((d) => {return d.id.toLowerCase();});
        event.filter((c) => {
            return deletedIds.indexOf(c.id.toLowerCase()) < 0;
        });
        return event;
    }*/
}