import { v4 as uuid } from 'uuid';
import axios from 'axios';
import Config from './../config';
import Request from './request';

// import interfaces
import { IUserDataProps, TUserRoleType, ILessonDataProps, IBasicUserDataProps } from './../props/data';

// import sample data
import userData from './../sample/user';

// import services
import TeacherLessonConnectionService from './../services/teacherLessonConnection';

interface IErrorDataProps {
    stage: "user" | "teacherLessonConnection";
    title: string;
    message: string;
}

interface ICreateUserProps extends IUserDataProps {
    password: string;
    repeatPassword: string;
}

export default class UserService {
    private teacherLessonConnectionService:TeacherLessonConnectionService = new TeacherLessonConnectionService();

    public async getById(id:string):Promise<IUserDataProps | undefined> {
        try {
            let results:any = await Request.get(`/api/user/${id}`);
            let user:IUserDataProps = results.data.data;
            return user;
        } catch(e) {
            throw(e);
        }
    }

    public async getAll(role:TUserRoleType):Promise<IUserDataProps[]> {
        try {
            let results:any = await Request.get("/api/user");
            let users:IUserDataProps[] = results.data.data;
            return users.filter((user) => {
                let lowerCaseRoles = user.roles.map((role) => {return role.toLowerCase();});
                return lowerCaseRoles.indexOf(role.toLowerCase()) > -1;
            });
        } catch(e) {
            throw(e);
        }
    }

    public async create(data:ICreateUserProps):Promise<IErrorDataProps[]> {
        let error:IErrorDataProps[] = [];
        try {
            if (data.maxLessonsPerWeek) {
                data.maxLessonsPerWeek = parseInt(data.maxLessonsPerWeek + "");
            }
            let result = await Request.post("/api/auth/register", data);
            let newUser:IUserDataProps = result.data.data as IUserDataProps;
            if (data.includedLessons) {
                for (var ctr=0; ctr<data.includedLessons.length; ctr ++) {
                    let lesson = data.includedLessons[ctr];
                    try {
                        let props = {
                            id: uuid(),
                            teacherId: newUser.id,
                            lessonId: lesson.id,
                            level: lesson.level != undefined ? lesson.level : 1,
                            priority: ctr+1
                        };
                        await this.teacherLessonConnectionService.create(props);
                    } catch(e) {
                        error.push({
                            stage: 'teacherLessonConnection',
                            title: lesson.text,
                            message: e
                        });
                    }
                }
            }

            return error;
        } catch(e) {
            error.push({
                stage: 'user',
                title: data.email,
                message: e
            });

            throw(error);
        }
    }

    public async resetPassword(id:string) {
        try {
            var generatedPassword = Math.random().toString(36).slice(-8) + Math.random().toString(36).slice(-4);
            await Request.patch(`/api/user/${id}`, {password: generatedPassword});
            return generatedPassword;
        } catch(e) {
            throw(e);
        }
    }

    public async updatePassword(oldPassword:string, newPassword:string) {
        let tryNumber = 1;
        let error = undefined;
        do {
            try {
                await Request.put(`/api/auth/updatePassword`, {oldPassword, newPassword});
                return {};
            } catch(e) {
                error = e.toString();
                if (error.indexOf("data was invalid") > -1) {
                    throw("Password lama salah.");
                }
                tryNumber++;
                await new Promise((resolve) => {setTimeout(() => {resolve()}, 5000)});
            }
        } while(tryNumber <= 10);
        throw(error);
    }

    public async updateProfile(id:string, data:{name:string, address?:string, email:string, phoneNumber?:string, city:string}) {
        let tryNumber = 1;
        let error = undefined;
        do {
            try {
                await Request.patch(`/api/user/${id}`, data);
                return {};
            } catch(e) {
                error = e.toString();
                tryNumber++;
                await new Promise((resolve) => {setTimeout(() => {resolve()}, 5000)});
            }
        } while(tryNumber <= 10);
        throw(error);
    }

    public async update(id:string, data:IUserDataProps) {
        /*await new Promise((resolve) => {setTimeout(() => {return resolve();}, 2000);});
        await Promise.all(userData.map(async (d) => {
            if (d.id === id) {
                d = data;
                let existingLessons = await this.teacherLessonConnectionService.getByTeacherId(data.id);
                let lessons = data.includedLessons ? data.includedLessons.map((conn, idx) => {
                    if (!existingLessons.find((lesson) => {return lesson.id === conn.key;})) {
                        return {
                            id: uuid(),
                            teacherId: data.id,
                            lessonId: conn.key,
                            level: conn.level,
                            priority: idx
                        };
                    } else {return undefined;}
                }) : [];
                await this.teacherLessonConnectionService.create(lessons as any);
            }
        }));
        return data;*/
        
        let error:IErrorDataProps[] = [];
        try {
            await Request.patch(`/api/user/${id}`, data);
            if (data.includedLessons) {
                for (var ctr=0; ctr<data.includedLessons.length; ctr ++) {
                    let lesson = data.includedLessons[ctr];
                    try {
                        // check if lesson is deleted
                        if (lesson.connectionId && lesson.deleted) {
                            await this.teacherLessonConnectionService.delete({id: lesson.connectionId});
                        } else if (lesson.connectionId && !lesson.deleted && lesson.touched) {
                            await this.teacherLessonConnectionService.update(lesson.connectionId, {
                                id: lesson.connectionId,
                                level: lesson.level,
                                priority: ctr+1,
                                lessonId: lesson.id,
                                teacherId: id
                            });
                        } else if (!lesson.connectionId && !lesson.deleted) {
                            await this.teacherLessonConnectionService.create({
                                id: uuid(),
                                level: lesson.level,
                                priority: ctr+1,
                                lessonId: lesson.id,
                                teacherId: id
                            });
                        }
                    } catch(e) {
                        error.push({
                            stage: 'teacherLessonConnection',
                            title: lesson.text,
                            message: e
                        });
                    }
                }
            }

            return data;
        } catch(e) {
            throw(e);
        }
    }

    public async delete(data:IUserDataProps[]) {
        await new Promise((resolve) => {setTimeout(() => {return resolve();}, 2000);});
        let deletedIds = data.map((d) => {return d.id.toLowerCase();});
        userData.filter((c) => {
            return deletedIds.indexOf(c.id.toLowerCase()) < 0;
        });
        return userData;
    }

    public async getCurrentUser():Promise<IUserDataProps> {
        try {
            let currentUser:any = await Request.get("/api/auth/user");
            return currentUser.data.data;
        } catch(e) {
            throw(e);
        }
    }
}