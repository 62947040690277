import React from 'react';

// import interfaces
import {IFormFieldProps} from './../formProps';

// import subcomponents
import FieldHeader from './../sub/fieldHeader';
import FieldFooter from './../sub/fieldFooter';

// import services
import Validation from './../services/validation';

// import fabric ui components
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

interface IFETextFieldState {
    field: IFormFieldProps;
    fields: IFormFieldProps[];
}

interface IFETextFieldProps extends IFETextFieldState {
    onValueChanged(key:string, value:string, errorMessage?:string): void;
}

export default class FETextField extends React.Component<IFETextFieldProps, IFETextFieldState> {
    constructor(props:IFETextFieldProps) {
        super(props);

        let {field, fields} = this.props;
        this.state = {
            field,
            fields
        }
    }

    public componentWillReceiveProps(props:IFETextFieldProps) {
        this.setState({field: props.field});
    }

    private _onTextFieldChanged = (evt?:any, value?:string) => {
        let {field, fields} = this.state;
        let error = Validation.field(field, value, fields);
        this.props.onValueChanged(field.key, value || "", error);
    }

    public render() {
        let {field} = this.state;
        return (
            <Stack tokens={{childrenGap: 5}}>
                <Stack.Item>
                    <FieldHeader title={field.title} required={field.isRequired} content={field.content}/>
                </Stack.Item>
                <Stack.Item>
                    <TextField disabled={field.isDisabled} value={field.value || ""} onChange={this._onTextFieldChanged}/>
                </Stack.Item>
                <Stack.Item>
                    <FieldFooter error={field.errorMessage} description={field.description}/>
                </Stack.Item>
            </Stack>
        );
    }
}