import { v4 as uuid } from 'uuid';
import Request from './request';

// import interfaces
import { IKEPDataProps } from './../props/data';

// import sample data
import kep from './../sample/kep';

export default class KEPService {
    public async getById(id:string):Promise<IKEPDataProps | undefined> {
        try {
            let results:any = await Request.get(`/api/kep/${id}`);
            let relatedKEP:IKEPDataProps = results.data.data;
            return relatedKEP;
        } catch(e) {
            throw(e);
        }
    }

    public async getAll():Promise<IKEPDataProps[]> {
        try {
            let results:any = await Request.get("/api/kep");
            let kep:IKEPDataProps[] = results.data.data;
            return kep;
        } catch(e) {
            throw(e);
        }
    }

    public async create(data:IKEPDataProps):Promise<IKEPDataProps> {
        try {
            data.id = uuid();
            let result:any = await Request.post("/api/kep", data);
            return result.data.data as IKEPDataProps;
        } catch(e) {
            throw(e);
        }
    }

    public async update(id:string, data:IKEPDataProps) {
        try {
            await Request.patch(`/api/kep/${id}`, data);
            return data;
        } catch(e) {
            throw(e);
        }
    }

    public async delete(data:IKEPDataProps) {
        try {
            await Request.delete(`/api/kep/${data.id}`);
            return data;
        } catch(e) {
            throw(e);
        }
    }
}