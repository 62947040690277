import React from 'react';
import moment from 'moment';
import dashboardStyle from './dashboard.module.scss';

// import components
import TeacherScheduleList from './../../components/schedule/teacherScheduleList';

// import interfaces
import { 
    IUserDataProps 
} from '../../props/data';

// import services
import UserService from './../../services/user';

// import fabric ui
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { ActionButton, CommandBar, PrimaryButton } from '@fluentui/react';

// local interfaces
interface IDashboardPageProps {}
interface IDashboardPageState {
    loaded?: boolean;
    currentUser?: IUserDataProps;
}

export default class DashboardPage extends React.Component<IDashboardPageProps, IDashboardPageState> {
    private userService:UserService = new UserService();

    constructor(props: IDashboardPageProps) {
        super(props);
        this.state = {};
    }
    
    public async componentDidMount() {
        let currentUser:IUserDataProps = await this.userService.getCurrentUser();
        
        this.setState({
            currentUser,
            loaded: true
        })
    }

    public render() {
        if (this.state.loaded && this.state.currentUser) {
            const {currentUser} = this.state;
            return (
                <Stack className={dashboardStyle.dashboard} horizontal tokens={{childrenGap: 20}} wrap>
                    <Stack grow={2}>
                        {/* render teacher schedule in next 2 weeks */}
                        <Stack tokens={{childrenGap: 15}} className={dashboardStyle.container}>
                            <Stack.Item><Text variant={"large"} style={{fontWeight: 600}}>Jadwal Mengajar 2 Minggu Kedepan</Text></Stack.Item>
                            <Stack.Item>
                                <TeacherScheduleList teacher={currentUser} startDate={moment().toISOString()} endDate={moment().add(2, 'weeks').toISOString()} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <Stack className={dashboardStyle.container} grow={1}>
                        {/* render user profile */}
                        <Stack tokens={{childrenGap: 15}}>
                            <Stack.Item><Text variant={"large"} style={{fontWeight: 600}}>Profil Saya</Text></Stack.Item>
                            <Stack tokens={{childrenGap: 10}}>
                                <Stack>
                                    <Stack.Item><Text variant="medium" style={{fontWeight: 600}}>Nama</Text></Stack.Item>
                                    <Stack.Item><Text variant="small">{currentUser.name}</Text></Stack.Item>
                                </Stack>
                                <Stack>
                                    <Stack.Item><Text variant="medium" style={{fontWeight: 600}}>Email</Text></Stack.Item>
                                    <Stack.Item><Text variant="small">{currentUser.email}</Text></Stack.Item>
                                </Stack>
                                <Stack>
                                    <Stack.Item><Text variant="medium" style={{fontWeight: 600}}>Alamat</Text></Stack.Item>
                                    <Stack.Item><Text variant="small">{currentUser.address && currentUser.address.length > 0 ? currentUser.address : "-"}</Text></Stack.Item>
                                </Stack>
                                <Stack>
                                    <Stack.Item><Text variant="medium" style={{fontWeight: 600}}>No. Telepon</Text></Stack.Item>
                                    <Stack.Item><Text variant="small">{currentUser.phoneNumber && currentUser.phoneNumber.length > 0 ? currentUser.phoneNumber : "-"}</Text></Stack.Item>
                                </Stack>
                                <Stack>
                                    <Stack.Item><Text variant="medium" style={{fontWeight: 600}}>Wewenang</Text></Stack.Item>
                                    <Stack.Item><Text variant="small">{currentUser.roles.join(", ")}</Text></Stack.Item>
                                </Stack>
                            </Stack>
                            <Stack.Item>
                                <PrimaryButton styles={
                                    {root: {padding: 5}, 
                                    icon: {fontSize: "small"}, 
                                    textContainer: {fontSize: "small"}}} 
                                    iconProps={{iconName: "Edit"}} 
                                    onClick={() => {window.location.href = "/akun"}}>
                                    Kelola Akun
                                </PrimaryButton>
                            </Stack.Item>
                        </Stack>
                    </Stack>
                </Stack>
            );
        } else {
            return <Spinner label="Memuat data ..." labelPosition={"bottom"} size={SpinnerSize.large}/>
        }
    }
}
