import React from 'react';

// import styles
import kepInternalStyles from './../../styles/kepinternal.module.scss';

// import interfaces
import { IKEPDataProps, IUserDataProps } from './../../props/data';
import { IListPanelClosed, IFilterProps, IFilterOptionProps } from './../../props/general';

// import services
import KEPService from './../../services/kep';
import GeneralService from './../../services/general';

// import components
import KEPPropsPanel from './KEPPropsPanel';
import DeleteDataPanel, {IDeleteDataPanelOnDeleteReturn} from './../panels/deleteData';
import FilterDataPanel from './../panels/filterData';

// import fabric ui
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { DetailsList, IColumn, Selection, SelectionMode, DetailsListLayoutMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ContextualMenu, IContextualMenuProps, ContextualMenuItemType } from 'office-ui-fabric-react/lib/ContextualMenu';

// local interfaces
interface IDeletedDataProps extends IKEPDataProps {
    status: string;
    message?: string;
}

interface IKEPListProps {
    listColumns: IColumn[];
    includeCommandBar: boolean;
    hasActionButton: boolean;
    filterable?: string[];
    sortable?: string[];
    searchBy?: string[];
}

interface IKEPListState {
    commandBar: {
        leftItems?: ICommandBarItemProps[];
        rightItems?: ICommandBarItemProps[];
    };
    messageBar?: {text: string; type: MessageBarType};
    data: IKEPDataProps[];
    deletedData?: IDeletedDataProps[];
    raw: IKEPDataProps[];
    searchKeyword: string;
    selectedData?: IKEPDataProps[];
    loading?: boolean;
    loadingDeletion?: boolean;
    listColumns: IColumn[];
    components: {
        KEPPropsPanelOpened?: boolean;
        DeletePanelOpened?: boolean;
        FilterPanelOpened?: boolean;
    };
    selectedHeader?: IColumn;
    columnHeaderContextMenu?: IContextualMenuProps;
    filters: IFilterProps[];
    sortBy?: {
        fieldName: string;
        type: 'asc'|'desc';
    };
}

export default class KEPList extends React.Component<IKEPListProps, IKEPListState> {
    private KEPService:KEPService = new KEPService();
    private _selection = new Selection({
        onSelectionChanged: () => {this._onSelectionChanged(this._selection.getSelection() as IKEPDataProps[])}
    });

    constructor(props: IKEPListProps) {
        super(props);

        this.state = {
            commandBar: {},
            data: [],
            raw: [],
            loading: true,
            listColumns: [],
            components: {},
            filters: [],
            searchKeyword: ""
        }
    }

    private _onSelectionChanged = (selectedData:IKEPDataProps[]) => {
        let leftItems = this.state.commandBar.leftItems;
        let deleteClassItem = leftItems ? leftItems.find((item) => {return item.key === 'deleteKEP'}) : undefined;
        if (deleteClassItem) {
            deleteClassItem.disabled = !selectedData || (selectedData && selectedData.length < 1);
        }

        this.setState({
            commandBar: {leftItems}
        });
    }

    private _onColumnHeaderClicked = (sortable:boolean, filterable:boolean, ev?:any, column?:IColumn) => {
        let items = [];

        if (filterable) {
            items.push({
                key: 'filterBy',
                iconProps: {iconName: 'Filter'},
                text: 'Filter',
                title: 'Filter',
                onClick: () => {this.setState({components: {FilterPanelOpened: true}});}
            });
        }
        if (sortable) {
            items.push({
                key: 'sortSection',
                itemType: ContextualMenuItemType.Section,
                sectionProps: {
                    topDivider: true,
                    title: 'Urutkan',
                    items: [
                        {
                            key: 'asc',
                            text: 'A to Z (Ascending)',
                            iconProps: {iconName: "SortUp"},
                            onClick: () => {this._onSort('asc');}
                        },
                        {
                            key: 'desc',
                            text: 'Z to A (Descending)',
                            iconProps: {iconName: "SortDown"},
                            onClick: () => {this._onSort('desc');}
                        }
                    ]
                }
            })
        };

        this.setState({
            selectedHeader: column,
            columnHeaderContextMenu: {
                items,
                target: ev.currentTarget as HTMLElement,
                gapSpace: 10,
                onDismiss: () => {this.setState({columnHeaderContextMenu: undefined})}
            }
        })
    }

    public async componentDidMount() {
        let leftItems:ICommandBarItemProps[] = [
            {
                key: 'searchKEP',
                onRender: () => {
                    return (
                        <SearchBox className={kepInternalStyles.searchBox} placeholder="Cari KEP" onChange={this._onSearch}/>
                    );
                }
            }
        ];

        if (GeneralService.isCurrentUserAdmin() || GeneralService.isCurrentUserSecretary()) {
            leftItems = [...leftItems, ...[
                    {
                        key: 'newKEP',
                        text: 'Tambah KEP Baru',
                        iconProps: { iconName: 'Add' },
                        onClick: () => {
                            this.setState({selectedData: undefined, components:{KEPPropsPanelOpened: true}});
                        }
                    },
                    {
                        key: 'deleteKEP',
                        text: 'Hapus KEP',
                        iconProps: { iconName: 'Delete' },
                        disabled: true,
                        onClick: () => {this.setState({deletedData: undefined, components:{DeletePanelOpened: true}});}
                    }
                ]
            ]
        }

        let rawData = await this.KEPService.getAll();
        let listColumns:IColumn[] = this.props.listColumns;
        let {sortable, filterable} = this.props;
        listColumns = listColumns.map((column) => {
            let isSortable:boolean = (sortable && sortable.indexOf(column.fieldName || column.key) > -1) || false;
            let isFilterable:boolean = (filterable && filterable.indexOf(column.fieldName || column.key) > -1) || false;
            if (isSortable || isFilterable) {
                column.iconName = "ChevronDown";
                column.styles = {iconClassName: {marginRight: 5}};
                column.onColumnClick = (ev, column) => {this._onColumnHeaderClicked(isSortable, isFilterable, ev, column)};
            }
            return column;
        });

        if (this.props.hasActionButton && (GeneralService.isCurrentUserAdmin() || GeneralService.isCurrentUserSecretary())) {
            listColumns.splice(1, 0, {
                key: 'actionButtons',
                name: '',
                minWidth: 20,
                maxWidth: 20,
                isIconOnly: true,
                className: kepInternalStyles.listActionButtons,
                onRender: (item?:any) => {
                    return (
                        <IconButton
                            onMenuClick={() => {
                                this.setState({
                                    selectedData: [item as IKEPDataProps]
                                });
                            }}
                            menuProps={{
                                items: [
                                    {
                                        key: 'updateKEP',
                                        text: 'Ubah data',
                                        iconProps: { iconName: 'Edit' },
                                        onClick: () => {this.setState({components:{KEPPropsPanelOpened: true}});}
                                    }
                                ]
                            }}
                            title="Tombol aksi"
                            ariaLabel="Tombol aksi"/>
                    );
                }
            });
        }

        this.setState({
            commandBar: {leftItems},
            listColumns,
            raw: rawData,
            data: rawData,
            loading: false
        });
    }

    private async _onRefreshData() {
        this.setState({loading: true});
        let data = await this.KEPService.getAll();
        this.setState({data, loading: false});
    }

    private _onPanelClosed = async (props?:IListPanelClosed):Promise<void> => {
        this.setState({components: {}});
        if (props && props.refreshData) {this._onRefreshData();}
        if (props && props.messageBar) {this.setState({messageBar: props.messageBar});}
    }

    private processData() {
        let {filters, sortBy, raw, searchKeyword, data} = this.state;
        let {searchBy} = this.props;

        // filter
        data = GeneralService.filterData(raw, filters);

        // search
        searchKeyword = searchKeyword.trim();
        if (searchKeyword !== "" && searchBy) {
            data = GeneralService.searchData(data, searchBy, searchKeyword);
        }

        // sort
        if (sortBy) {
            data = GeneralService.sortData(data, sortBy.fieldName, sortBy.type);
        }

        this.setState({data, loading: false});
    }
    
    private _onFilterApplied = (fieldName: string, selectedOptions:IFilterOptionProps[]) => {
        this.setState({loading:true, components: {}});

        let {raw, filters, selectedHeader} = this.state;
        let relatedFilter = filters.find((filter) => {return filter.fieldName === fieldName;});
        if (relatedFilter) {
            if (selectedOptions.length > 0) {
                relatedFilter.selectedOptions = selectedOptions;
            } else {
                let relatedFilterIndex = filters.findIndex((filter) => {return filter.fieldName === fieldName;});
                filters.splice(relatedFilterIndex, 1);
            }
        } else if (selectedOptions.length > 0) {
            filters.push({
                fieldName,
                text: selectedHeader ? selectedHeader.name : "",
                selectedOptions
            });
        }

        this.setState({filters}, () => {this.processData();});
    }

    private _onSearch = (evt?:any, key?:string) => {
        this.setState({
            loading:true, 
            components: {}, 
            searchKeyword: key ? key.trim() : ""
        }, () => {this.processData();});
    }

    private _onSort = (type:"asc"|"desc") => {
        let {selectedHeader} = this.state;
        if (selectedHeader) {
            this.setState({
                loading:true, 
                components: {}, 
                sortBy:{fieldName: selectedHeader.fieldName || selectedHeader.key, type}
            }, () => {this.processData();});
        }
    }

    private _onDelete = async (data:any):Promise<IDeleteDataPanelOnDeleteReturn> => {
        try {
            let deletedData:IDeletedDataProps[] = (data as IKEPDataProps[]).map((d) => {
                return {
                    ...d,
                    status: 'inprogress'
                }
            });

            this.setState({deletedData}, async () => {
                for (var ctr=-0; ctr<deletedData.length; ctr++) {
                    let d = deletedData[ctr];
                    try {
                        await this.KEPService.delete(d as IKEPDataProps);
                        d.status = 'completed';
                        this.setState({deletedData});
                    } catch(e) {
                        d.status = 'error';
                        d.message = e;
                        this.setState({deletedData});
                    }
                }
            });

            return {
                success: true,
                message: "",
                delay: 5000
            }
        } catch(e) {
            return {
                success: false,
                message: "Maaf, sistem mengalami masalah saat menghapus data. Pesan error: " + e.toString()
            }
        }
    }

    public render() {
        let {selectedHeader, filters} = this.state;
        return (
            <Stack tokens={{childrenGap: 10}}>
                {
                    this.props.includeCommandBar ? (
                        <Stack.Item>
                            <CommandBar
                                items={this.state.commandBar.leftItems || []}
                                farItems={this.state.commandBar.rightItems || []}
                                ariaLabel="Gunakan panah kiri dan kanan untuk berpindah tombol"
                                className={kepInternalStyles.commandBar} 
                                styles={{root:{padding: 0}}}/>
                        </Stack.Item>
                    ) : null
                }
                {
                    this.state.messageBar ? (
                        <Stack.Item>
                            <MessageBar messageBarType={this.state.messageBar.type} isMultiline={true} onDismiss={() => {this.setState({messageBar: undefined})}}>
                                {this.state.messageBar.text}
                            </MessageBar>
                        </Stack.Item>
                    ) : null
                }
                <Stack.Item>
                    {
                        this.state.loading ? (
                            <Spinner label="Memuat data KEP ..." labelPosition="bottom" size={SpinnerSize.large} />
                        ) : null
                    }
                    { this.state.columnHeaderContextMenu ? <ContextualMenu {...this.state.columnHeaderContextMenu}/> : null }
                    {
                        !this.state.loading ? (
                            <DetailsList
                                selection={this._selection}
                                items={this.state.data}
                                compact={false}
                                columns={this.state.listColumns}
                                selectionMode={SelectionMode.multiple}
                                setKey="none"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}/>
                        ) : null
                    }
                    {!this.state.loading && this.state.data.length < 1 ? <Text style={{padding: 10}}>KEP tidak dapat ditemukan. Tekan tombol "Tambah KEP baru" untuk membuat KEP baru.</Text> : null}
                </Stack.Item>
                <Stack.Item>
                    <KEPPropsPanel data={this.state.selectedData ? this.state.selectedData[0] : undefined} onPanelClosed={this._onPanelClosed} isOpen={this.state.components.KEPPropsPanelOpened || false} />
                </Stack.Item>
                <Stack.Item>
                    <DeleteDataPanel data={this.state.deletedData || this._selection.getSelection()}
                        columns={[
                            {key: "name", fieldName: "name", name: "Nama", minWidth: 0},
                            {key: "status", fieldName: "status", name: "Status", minWidth: 0, onRender: (item) => {
                                if (item.status === 'inprogress') {return <Spinner size={SpinnerSize.small} label="Menghapus ..." labelPosition={"right"}/>}
                                else if (item.status === 'completed') {return <Text><Icon iconName="CheckMark"/> Berhasil</Text>}
                                else if (item.status === 'error') {return <Text><Icon iconName="Error"/> Gagal</Text>}
                            }}
                        ]}
                        isOpen={this.state.components.DeletePanelOpened || false}
                        onPanelClosed={() => this._onPanelClosed({refreshData: true})}
                        onDelete={this._onDelete}/>
                </Stack.Item>
                <Stack.Item>
                    <FilterDataPanel isOpen={this.state.components.FilterPanelOpened || false}
                        data={this.state.raw}
                        fieldName={selectedHeader ? selectedHeader.fieldName || selectedHeader.key : ""}
                        panelHeader={selectedHeader ? `Filter '${selectedHeader.name}'` : ""}
                        onPanelClosed={this._onPanelClosed}
                        onFilterApplied={this._onFilterApplied}
                        currentFilters={filters}/>
                </Stack.Item>
            </Stack>
        );
    }
}
