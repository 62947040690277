import React from 'react';

// import styles
import kepInternalStyles from './../../styles/kepinternal.module.scss';

// import components
import Form from './../../components/forms/index';
import {IFormFieldProps} from './../../components/forms/formProps';

// import interfaces
import { IClassDataProps } from './../../props/data';
import { IListPanelClosed } from './../../props/general';

// import services
import ClassService from './../../services/class';
import FormMetadataService from './../../components/forms/services/metadata';
import FormValidationService from './../../components/forms/services/validation';

// import fabric ui
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Panel } from 'office-ui-fabric-react/lib/Panel';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';

// local interfaces

interface IClassPropsPanelProps {
    isOpen: boolean;
    data?: IClassDataProps;
    onPanelClosed(props?:IListPanelClosed): void;
}

interface IClassPropsPanelState {
    isOpen: boolean;
    saving: boolean;
    data?: IClassDataProps;
    isUpdate: boolean;
    formFields: IFormFieldProps[];
}

export default class ClassPropsPanel extends React.Component<IClassPropsPanelProps, IClassPropsPanelState> {
    private classService:ClassService = new ClassService();
    private defaultFormFields:IFormFieldProps[] = [
        {
            promoted: true,
            key: "id",
            title: "ID",
            type: "textfield",
            isRequired: false,
            isDisabled: true,
            isHidden: true
        },
        {
            promoted: true,
            key: "name",
            title: "Nama kelas",
            type: "textfield",
            isRequired: true,
            isDisabled: false
        },
        {
            promoted: true,
            key: "order",
            title: "Urutan",
            type: "number",
            isRequired: true,
            isDisabled: false
        }
    ]

    constructor(props: IClassPropsPanelProps) {
        super(props);

        this.state = {
            isOpen: false,
            saving: false,
            isUpdate: props.data ? true : false,
            data: props.data,
            formFields: JSON.parse(JSON.stringify(this.defaultFormFields))
        }
    }

    public componentWillReceiveProps(props: IClassPropsPanelProps) {
        this.setState({
            isOpen: props.isOpen,
            isUpdate: props.data ? true : false,
            data: props.data,
            formFields: JSON.parse(JSON.stringify(this.defaultFormFields))
        });
    }

    private _onPanelClosed = (props?:IListPanelClosed) => {
        this.props.onPanelClosed(props);
    }

    private _onCreate = async ():Promise<void> => {
        let fieldsValidation = FormValidationService.fields(this.state.formFields);
        if (!fieldsValidation.isError) {
            this.setState({
                saving: true, 
                formFields: this.state.formFields.map((field) => {
                    field.isDisabled = true;
                    return field;
                })
            });

            let metadata = FormMetadataService.generate(this.state.formFields);
            let newData = await this.classService.create(metadata);
            this.setState({saving: false});
            this._onPanelClosed({
                refreshData: true, 
                messageBar: {
                    text: `Modul ${newData.name} berhasil ditambahkan`,
                    type: MessageBarType.success
                }
            });
        } else {
            this.setState({
                saving: false,
                formFields: fieldsValidation.fields.map((field) => {
                    field.isDisabled = false;
                    return field;
                })
            });
        }
    }

    private _onUpdate = async ():Promise<void> => {
        let fieldsValidation = FormValidationService.fields(this.state.formFields);
        if (!fieldsValidation.isError) {
            this.setState({
                saving: true, 
                formFields: this.state.formFields.map((field) => {
                    field.isDisabled = true;
                    return field;
                })
            });

            let metadata = FormMetadataService.generate(this.state.formFields);
            let updatedData = await this.classService.update(metadata.id, metadata);
            this.setState({saving: false});
            this._onPanelClosed({
                refreshData: true, 
                messageBar: {
                    text: `Kelas ${updatedData.name} berhasil diubah`,
                    type: MessageBarType.success
                }
            });
        }
    }

    private renderPanelFooter = ():JSX.Element => {
        return (
            <Stack horizontalAlign="baseline">
                {
                    this.state.saving ? (
                        <Spinner label={this.state.isUpdate ? "Megubah data kelas ... " : "Menambahkan kelas baru ... "} labelPosition="right" size={SpinnerSize.medium}/>
                    ) : null
                }
                {
                    !this.state.saving ? (
                        <Stack horizontal tokens={{childrenGap: 10}}>
                            <Stack.Item>
                            { this.state.isUpdate ? <PrimaryButton label={"Ubah"} onClick={this._onUpdate}>Ubah</PrimaryButton> : null }
                            { !this.state.isUpdate ? <PrimaryButton label={"Tambahkan"} onClick={this._onCreate}>Tambahkan</PrimaryButton> : null }
                            </Stack.Item>
                            <Stack.Item>
                                <DefaultButton label={"Tambahkan"} onClick={() => this._onPanelClosed()}>Batal</DefaultButton>
                            </Stack.Item>
                        </Stack>
                    ) : null
                }
            </Stack>
        );
    }

    private _onFormFieldsChanged = (fields:IFormFieldProps[]) => {
        this.setState({formFields: fields, data: undefined});
    }

    public render() {
        return (
            <Panel
                headerText={this.props.data ? "Ubah Data Kelas" : "Buat Kelas Baru"}
                isOpen={this.state.isOpen}
                onDismiss={() => this._onPanelClosed()}
                closeButtonAriaLabel="Tutup"
                onRenderFooterContent={this.renderPanelFooter}
                isFooterAtBottom={true}>
                <Form fields={this.state.formFields} onValueChanged={this._onFormFieldsChanged} defaultValue={this.state.data}/>
            </Panel>
        );
    }
}
