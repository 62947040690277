import React from 'react';
import moment from 'moment';
import {Link} from "react-router-dom";

// import components
import ScheduleList, {IAllDataProps} from '../../components/schedule/scheduleList';

// import interfaces
import {IUserDataProps, IKEPDataProps, IScheduleDataProps, ISchedulePreviewProps, IIntakeDataProps, IEventDataProps, ISchedulePreviewLessonProps} from './../../props/data';

// import services
import ScheduleService from './../../services/schedule';
import KEPService from './../../services/kep';
import ClassService from './../../services/class';
import ModuleService from './../../services/module';
import LessonService from './../../services/lesson';
import UserService from './../../services/user';
import IntakeService from './../../services/intake';
import GeneralService from './../../services/general';

// import fabric ui
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';

// local interfaces
interface ISchedulePageProps {}
interface ISchedulePageState {
    loaded?: boolean;
    loadingSchedule: boolean;
    schedules?: IScheduleDataProps[];
    schedulesPreview?: ISchedulePreviewProps[];
    data: IAllDataProps;
    selected: {
        KEP?: string;
        intake?: string;
    }
}

export default class SchedulePage extends React.Component<ISchedulePageProps, ISchedulePageState> {
    private userService:UserService = new UserService();
    private kepService:KEPService = new KEPService();
    private scheduleService:ScheduleService = new ScheduleService();
    private classService:ClassService = new ClassService();
    private moduleService:ModuleService = new ModuleService();
    private lessonService:LessonService = new LessonService();
    private intakeService:IntakeService = new IntakeService();

    constructor(props: ISchedulePageProps) {
        super(props);

        this.state = {
            loadingSchedule: false,
            selected: {},
            data: {KEP: [], classes: [], modules: [], lessons: [], events: [], teachers: [], intakes: []}
        };
    }

    public async componentDidMount() {
        let teachers:IUserDataProps[] = await this.userService.getAll('Guru');
        let KEP:IKEPDataProps[] = await this.kepService.getAll();
        let classes = await this.classService.getAll();
        let modules = await this.moduleService.getAll();
        let lessons = await this.lessonService.getAll();
        let intakes = await this.intakeService.getAll();

        // get query string
        let selectedKEPId = GeneralService.getParameterByName("kep");
        let selectedIntakeId = GeneralService.getParameterByName("intake");

        this.setState({
            data: {
                KEP: JSON.parse(JSON.stringify(KEP)), 
                classes: JSON.parse(JSON.stringify(classes)), 
                modules: JSON.parse(JSON.stringify(modules)), 
                lessons: JSON.parse(JSON.stringify(lessons)), 
                events: [], 
                teachers: JSON.parse(JSON.stringify(teachers)),
                intakes: JSON.parse(JSON.stringify(intakes))
            },
            loaded: true,
            selected: {
                KEP: selectedKEPId && KEP.findIndex((kep) => {return kep.id === selectedKEPId;}) > -1 ? selectedKEPId : undefined,
                intake: selectedIntakeId && intakes.findIndex((intake) => {return intake.id === selectedIntakeId;}) > -1 ? selectedIntakeId : undefined
            }
        }, () => {
            this.getSchedules();
        })
    }

    private getSchedules = async () => {
        const {selected} = this.state;
        if (selected.KEP && selected.intake) {
            this.setState({loadingSchedule: true, schedules: undefined});
            let schedules:IScheduleDataProps[] = [];
            schedules = await this.scheduleService.getIntakeByKEPId(selected.KEP, selected.intake);
            
            let schedulesPreview = this.convertScheduleDataToSchedulePreview(schedules);
            
            this.setState({schedules, schedulesPreview, loadingSchedule: false});
        }
    }

    private updateQueryString = () => {
        let {selected} = this.state;

        if (window.history.pushState) {
            var newurl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""}${window.location.pathname}?${selected.KEP ? `kep=${selected.KEP}` : ""}${selected.KEP && selected.intake ? "&" : ""}${selected.intake ? `intake=${selected.intake}` : ""}`;
            window.history.pushState({path:newurl},'',newurl);
        }
    }

    private groupBy(list:any, keyGetter:any) {
        const map = new Map();
        list.forEach((item:any) => {
             const key = keyGetter(item);
             const collection = map.get(key);
             if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
        });
        return map;
    }

    private convertScheduleDataToSchedulePreview(schedules:IScheduleDataProps[]) {
        let schedulesGroupedByDate:[string, IScheduleDataProps[]][] = Array.from(this.groupBy(schedules, (schedule:any) => schedule.date));
        let schedulePreview:ISchedulePreviewProps[] = schedulesGroupedByDate.sort((a,b) => (a[0] > b[0]) ? 1 : ((b[0] > a[0]) ? -1 : 0)).map((data) => {
            let event:IEventDataProps | undefined = undefined;
            let lessons:ISchedulePreviewLessonProps[] | undefined = undefined;
            let values = data[1];
            let date = data[0];

            values.forEach((value) => {
                if (value.eventId) {
                    event = this.state.data.events.find((event) => {return event.id === value.eventId;});
                } if (value.lessonId ) {
                    let lesson = this.state.data.lessons.find((lesson) => {return lesson.id === value.lessonId;});
                    if (lesson) {
                        if (!lessons) {lessons = [];}
                        let props = {
                            ...lesson, 
                            scheduleId: value.id,
                            teacherId: value.teacherId ? value.teacherId : undefined,
                            teacher: value.teacherId ? this.state.data.teachers.find((teacher) => {return teacher.id === value.teacherId;}) : undefined, 
                            rerandom: !value.teacherId ? true : false
                        };
                        lessons.push(props);
                    }
                }
            });

            return {
                date,
                event,
                lessons
            };
        });

        return schedulePreview;
    }

    private getFullUrl = () => {
        let {selected} = this.state;
        return `/pengacakan?${selected.KEP ? "kep=" + selected.KEP : ""}&${selected.intake ? "intake=" + selected.intake : ""}`;
    }

    private getIntakeData = (intakeId:string):IIntakeDataProps | undefined => {
        let {intakes} = this.state.data;
        return intakes.find((intake) => {return intake.id === intakeId;});
    }

    private getKEPData = (kepId:string):IKEPDataProps | undefined => {
        let {KEP} = this.state.data;
        return KEP.find((kep) => {return kep.id === kepId;});
    }

    public render() {
        let selectedKEPData:IKEPDataProps |undefined = this.state.selected.KEP ? this.getKEPData(this.state.selected.KEP) : undefined; 
        let selectedIntakeData:IIntakeDataProps | undefined = this.state.selected.intake ? this.getIntakeData(this.state.selected.intake) : undefined;
        
        return (
            <Stack styles={{root: {padding: 20}}} tokens={{childrenGap: 15}}>
                <Stack.Item>
                    <h2>Jadwal KEP</h2>
                </Stack.Item>
                { !this.state.loaded ? <Spinner label={"Mempersiapkan data ..."} labelPosition={"bottom"} size={SpinnerSize.large}/> : null }
                {
                    this.state.loaded ? (
                        <Stack horizontal tokens={{childrenGap: 10}}>
                            <Stack.Item grow={1}>
                                <Dropdown
                                    placeholder="Pilih KEP ..."
                                    label="KEP"
                                    disabled={this.state.loadingSchedule}
                                    selectedKey={this.state.selected.KEP}
                                    onChange={(e?, selectedOption?:IDropdownOption) => {
                                        let {selected} = this.state;
                                        selected.KEP = selectedOption ? selectedOption.key as string : undefined;
                                        this.setState({selected}, () => {this.updateQueryString();this.getSchedules()});
                                    }}
                                    options={this.state.data.KEP.map((kep) => {return {key: kep.id, text: kep.name}})}/>
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <Dropdown
                                    placeholder="Pilih tahun pelajaran ..."
                                    label="Tahun pelajaran"
                                    disabled={this.state.loadingSchedule}
                                    selectedKey={this.state.selected.intake}
                                    onChange={(e?, selectedOption?:IDropdownOption) => {
                                        let {selected} = this.state;
                                        selected.intake = selectedOption ? selectedOption.key as string : undefined;
                                        this.setState({selected}, () => {this.updateQueryString();this.getSchedules()});
                                    }}
                                    options={this.state.data.intakes.map((intake) => {return {key: intake.id, text: intake.name}})}/>
                            </Stack.Item>
                        </Stack>
                    ) : null
                }
                { this.state.loadingSchedule ? <Spinner label={"Mendapatkan semua jadwal ..."} labelPosition={"bottom"} size={SpinnerSize.large}/> : null }
                {
                    this.state.schedules && this.state.schedules.length > 0 && selectedKEPData && selectedIntakeData ? (
                        <Stack.Item>
                            <ScheduleList allowDelete={true} data={this.state.data} schedules={this.state.schedulesPreview ? this.state.schedulesPreview : []} selected={{
                                KEP: selectedKEPData,
                                intake: selectedIntakeData,
                                startDate: this.state.schedules[0].date
                            }}/>
                        </Stack.Item>
                    ) : null
                }
                {
                    this.state.schedules && this.state.schedules.length < 1 ? (
                        <Text>Jadwal tidak dapat ditemukan. Klik <Link to={this.getFullUrl()}>link berikut</Link> untuk membuat jadwal baru.</Text>
                    ) : null
                }
            </Stack>
        );
    }
}
