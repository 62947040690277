import { v4 as uuid } from 'uuid';
import Request from './request';
import ClassService from './class';
import KEPService from './kep';

// import interfaces
import { IModuleDataProps, IKEPDataProps, ILessonDataProps, IClassDataProps } from './../props/data';

// import sample data
import moduleData from './../sample/module';

export default class ModuleService {
    private classService:ClassService = new ClassService();
    private kepService:KEPService = new KEPService();

    public async getById(id:string, expands?:('kep' | 'class')[]):Promise<IModuleDataProps | undefined> {
        try {
            let results:any = await Request.get(`/api/module/${id}`);
            let relatedModule:IModuleDataProps = results.data.data;
            // get related class
            relatedModule.class = await this.classService.getById(relatedModule.classId);

            if (expands && expands.indexOf("kep") > -1) {
                // get related kep
                let relatedKEPS = await Promise.all(relatedModule.includedKEPId.map(async (kepId) => {
                    return await this.kepService.getById(kepId);
                }));
                relatedKEPS.filter(Boolean);
                relatedModule.includedKEP = relatedKEPS as IKEPDataProps[];
            }

            return relatedModule;
        } catch(e) {
            throw(e);
        }
    }

    public async getAll(expands?:('kep' | 'class')[]):Promise<IModuleDataProps[]> {
        try {
            // get all modules
            let results:any = await Request.get("/api/module");
            let modules:IModuleDataProps[] = results.data.data;

            // get all keps
            let keps:IKEPDataProps[] = await this.kepService.getAll();

            // get all classes
            let classes:IClassDataProps[] = await this.classService.getAll();
            
            modules = await Promise.all(modules.map(async (module) => {
                // get related class
                if (module.classId) {
                    module.class = classes.find((cl) => {return cl.id === module.classId;}) as IClassDataProps;
                }

                // get related keps
                let relatedKEPS = module.includedKEPId.map((kepId) => {
                    return keps.find((kep) => {return kep.id === kepId;});
                });
                relatedKEPS.filter(Boolean);
                module.includedKEP = relatedKEPS as IKEPDataProps[];

                return module;
            }));
            return modules;
        } catch(e) {
            throw(e);
        }
        /*await new Promise((resolve) => {setTimeout(() => {return resolve();}, 2000);});
        return moduleData.map((module) => {
            module.class = classData.find((classProps) => {return classProps.id === module.classId;}) || {id: "", name: "", order: 0};
            module.includedKEP = kepData.filter((kep) => {return module.includedKEPId.indexOf(kep.id);});
            return module;
        });*/
    }

    public async create(data:IModuleDataProps):Promise<IModuleDataProps> {
        try {
            data.id = uuid();
            let result:any = await Request.post("/api/module", {...data, active: true});
            return result.data.data as IModuleDataProps;
        } catch(e) {
            throw(e);
        }
    }

    public async update(id:string, data:IModuleDataProps) {
        try {
            delete data.id;
            await Request.patch(`/api/module/${id}`, data);
            return data;
        } catch(e) {
            throw(e);
        }
    }

    public async delete(data:IModuleDataProps) {
        try {
            await Request.delete(`/api/module/${data.id}`);
            return data;
        } catch(e) {
            throw(e);
        }
    }
}