import { IFilterProps } from "../props/general";
import moment from 'moment';
import { IUserDataProps } from "../props/data";

declare var window:any;

const generalService = {
    getCurrentUser: function():IUserDataProps {
        return window.getCurrentUser();
    },
    isCurrentUserAdmin: function():boolean {
        return window.getCurrentUser() ? window.getCurrentUser().roles.indexOf("Administrator") > -1 : false;
    },
    isCurrentUserTeacher: function():boolean {
        return window.getCurrentUser() ? window.getCurrentUser().roles.indexOf("Guru") > -1 : false;
    },
    isCurrentUserSecretary: function():boolean {
        return window.getCurrentUser() ? window.getCurrentUser().roles.indexOf("Sekretariat") > -1 : false;
    },
    groupBy: function(list:any, keyGetter:any) {
        const map = new Map();
        list.forEach((item:any) => {
             const key = keyGetter(item);
             const collection = map.get(key);
             if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
        });
        return map;
    },
    getParameterByName: function(name:string, url?:string) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url || window.location.href);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    getUniqueFieldValues: function(data:any, fieldName:string):string[] {
        let uniqueValues:string[] = [];

        data.forEach((d:any) => {
            let value = d[fieldName];
            if (value && Array.isArray(value)) {
                value.forEach((v) => {
                    if (uniqueValues.indexOf(v) < 0) {
                        uniqueValues.push(v);
                    }
                })
            } else if (value && typeof(value) === 'string') {
                if (uniqueValues.indexOf(value) < 0) {
                    uniqueValues.push(value);
                }
            }
        });

        return uniqueValues;
    },
    filterData: function(data:any, filters:IFilterProps[]):any {
        return data.filter((d:any) => {
            let show = true;
            filters.forEach((filter) => {
                let value = d[filter.fieldName];
                let optionSelected = false;
                filter.selectedOptions.forEach((option) => {
                    if (value && Array.isArray(value) && value.indexOf(option) > -1) {
                        optionSelected = true;
                    } else if (value && value.toLowerCase() === option.key.toLowerCase()) {
                        optionSelected = true;
                    }
                });

                if (!optionSelected) {show = false;}
            });

            return show;
        });
    },
    searchData: function(data:any, searchBy:string[], keyword:string) {
        data = data.filter((d:any) => {
            let show = false;
            searchBy.forEach((fieldName) => {
                let value = d[fieldName];
                if (value && Array.isArray(value)) {
                    value.forEach((v) => {
                        if (v.toLowerCase().indexOf(keyword) > -1) {
                            show = true;
                        }
                    });
                } else if (value && value.toLowerCase().indexOf(keyword) > -1) {
                    show = true;
                }
            });
            return show;
        });

        return data;
    },
    sortData: function(data:any, sortBy:string, type:'asc' | 'desc') {
        data = data.sort((a:any, b:any) => {
            let avalue = a[sortBy] || "";
            let bvalue = b[sortBy] || "";
            if (Array.isArray(avalue)) {
                avalue = avalue.join(",");
            }
            if (Array.isArray(bvalue)) {
                bvalue = bvalue.join(",");
            }

            if (avalue.toLowerCase() > bvalue.toLowerCase()) return 1;
            if (bvalue.toLowerCase() > avalue.toLowerCase()) return -1;
          
            return 0;
        });
        if (type === 'desc') {
            data = data.reverse();
        }
        return data;
    },
    getInitial: function(name:string) {
        let splittedName = name.split(" ").filter((n) => {return n !== "";});
        if (splittedName.length > 1) {
            return (splittedName[0][0] + splittedName[1][0]).toUpperCase();
        } else if (splittedName.length > 0){
            return (splittedName[0][0] + splittedName[0][1]).toUpperCase();
        } else {
            return "";
        }
    },
    getDaysOfWeek: function() {
        return ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
    },
    getStartOfWeek: function(date:string) {
        return moment(date).isoWeekday(0).toISOString();
    }
}

export default generalService;