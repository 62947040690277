import React from 'react';

// import services
import GeneralService from './../../../services/general';

// import components
import UserList from './../../../components/user/userList';

// import fabric ui
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { IColumn } from 'office-ui-fabric-react/lib/DetailsList';

// local interfaces
interface IUserAdministratorProps {}
interface IUserAdministratorState {}

export default class UserAdministrator extends React.Component<IUserAdministratorProps, IUserAdministratorState> {
    private listColumns:IColumn[] = [
        {
            key: "userName",
            fieldName: "name",
            name: "Nama Lengkap",
            minWidth: 0
        },
        {
            key: "email",
            fieldName: "email",
            name: "Alamat Email",
            minWidth: 200
        },
        {
            key: "phoneNumber",
            fieldName: "phoneNumber",
            name: "No. Telepon",
            minWidth: 100
        },
        {
            key: "city",
            fieldName: "city",
            name: "Domisili",
            minWidth: 0
        },
        {
            key: "address",
            fieldName: "address",
            name: "Alamat Lengkap",
            minWidth: 250
        },
    ];

    constructor(props: IUserAdministratorProps) {
        super(props);
    }

    public render() {
        const isUserAdmin = GeneralService.isCurrentUserAdmin();
        return (
            <Stack styles={{root: {marginTop: 20}}}>
                <Stack.Item>
                    <UserList allowCreate={isUserAdmin} allowUpdate={isUserAdmin} allowDelete={isUserAdmin} allowResetPassword={isUserAdmin} role={"Administrator"} listColumns={this.listColumns} includeCommandBar={true} hasActionButton={true}/>
                </Stack.Item>
            </Stack>
        );
    }
}
