import axios from 'axios';
import Config from '../config';
import Cookies from 'js-cookie';

declare var window:any;

const Request = {
    get: async function(api: string) {
        let error = "";
        let retryNumber = 1;
        do {      
            try {
                let token = Cookies.get('token');
                let results = await axios.get(Config.hostname + api, {
                    headers: {
                        authorization: "Bearer " + token
                    }
                });
                return results;
            } catch(e) {
                retryNumber++;
                error = (e && e.response && e.response.data && e.response.data.message ? e.response.data.message : e.toString());
                await new Promise((resolve) => {setTimeout(() => {resolve()}, 5000)});
            }
        } while(retryNumber < 5);
        throw(error);
    },
    post: async function(api:string, body:any) {
        let error = "";
        let retryNumber = 1;
        do {      
            try {
                let token = Cookies.get('token');
                let result = await axios.post(Config.hostname + api, body, {
                    headers: {
                        authorization: "Bearer " + token,
                        'content-type': "application/json"
                    }
                });
                return result;
            } catch(e) {
                retryNumber++;
                error = (e && e.response && e.response.data && e.response.data.message ? e.response.data.message : e.toString());
                await new Promise((resolve) => {setTimeout(() => {resolve()}, 5000)});
            }
        } while(retryNumber < 5);
        throw(error);
    },
    patch: async function(api:string, body:any) {
        let error = "";
        let retryNumber = 1;
        do {      
            try {
                let token = Cookies.get('token');
                let result = await axios.patch(Config.hostname + api, body, {
                    headers: {
                        authorization: "Bearer " + token,
                        'content-type': "application/json"
                    }
                });
                return result;
            } catch(e) {
                retryNumber++;
                error = (e && e.response && e.response.data && e.response.data.message ? e.response.data.message : e.toString());
                await new Promise((resolve) => {setTimeout(() => {resolve()}, 5000)});
            }
        } while(retryNumber < 5);
        throw(error);
    },
    put: async function(api:string, body:any) {
        let error = "";
        let retryNumber = 1;
        do {      
            try {
                let token = Cookies.get('token');
                let result = await axios.put(Config.hostname + api, body, {
                    headers: {
                        authorization: "Bearer " + token,
                        'content-type': "application/json"
                    }
                });
                return result;
            } catch(e) {
                retryNumber++;
                error = (e && e.response && e.response.data && e.response.data.message ? e.response.data.message : e.toString());
                await new Promise((resolve) => {setTimeout(() => {resolve()}, 5000)});
            }
        } while(retryNumber < 5);
        throw(error);
    },
    delete: async function(api:string) {
        let error = "";
        let retryNumber = 1;
        do {      
            try {
                let token = Cookies.get('token');
                let result = await axios.delete(Config.hostname + api, {
                    headers: {
                        authorization: "Bearer " + token,
                        'content-type': "application/json"
                    }
                });
                return result;
            } catch(e) {
                retryNumber++;
                error = (e && e.response && e.response.data && e.response.data.message ? e.response.data.message : e.toString());
                await new Promise((resolve) => {setTimeout(() => {resolve()}, 5000)});
            }
        } while(retryNumber < 5);
        throw(error);
    }
}

export default Request;