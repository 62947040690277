import React from 'react';
import moment from 'moment';

// import components
import EventList from './../../components/event/eventList';

// import fabric ui
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { IColumn } from 'office-ui-fabric-react/lib/DetailsList';

// local interfaces
interface IEventPageProps {}
interface IEVentPageState {}

export default class EventPage extends React.Component<IEventPageProps, IEVentPageState> {
    private listColumns:IColumn[] = [
        {
            key: 'name',
            name: 'Nama Acara',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 200,
            data: 'string',
            isPadded: true
        },
        {
            key: 'type',
            name: 'Tipe Acara',
            fieldName: 'type',
            minWidth: 0,
            data: 'string',
            isPadded: true
        },
        {
            key: 'startDate',
            name: 'Tanggal Mulai',
            fieldName: 'startDate',
            minWidth: 0,
            data: 'string',
            isPadded: true,
            onRender: (item?:any) => {
                if (item && item.startDate) {
                    return <Text>{moment(item.startDate).format("DD/MM/YYYY")}</Text>;
                } else {return "-";}
            }
        },
        {
            key: 'endDate',
            name: 'Tanggal Berakhir',
            fieldName: 'endDate',
            minWidth: 0,
            data: 'string',
            isPadded: true,
            onRender: (item?:any) => {
                if (item && item.startDate) {
                    return <Text>{moment(item.startDate).format("DD/MM/YYYY")}</Text>;
                } else {return "-";}
            }
        }
    ];

    constructor(props: IEventPageProps) {
        super(props);
    }

    public render() {
        return (
            <Stack styles={{root: {padding: 20}}}>
                <Stack.Item>
                    <h2>Acara Bersama</h2>
                </Stack.Item>
                <Stack.Item>
                    <EventList listColumns={this.listColumns} includeCommandBar={true} hasActionButton={true} searchBy={["name", "type"]} sortable={["name", "type", "startDate", "endDate"]} filterable={["name", "type"]}/>
                </Stack.Item>
            </Stack>
        );
    }
}
