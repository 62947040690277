import React, { useState } from 'react';

// import interfaces
import { IFormFieldProps } from './../formProps';

// import subcomponents
import FieldHeader from './../sub/fieldHeader';
import FieldFooter from './../sub/fieldFooter';

// import services
import Validation from './../services/validation';

// import fabric ui components
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { DetailsList, SelectionMode, IDragDropEvents, IDragDropContext } from 'office-ui-fabric-react/lib/DetailsList';

interface IFESortingState {
    field: IFormFieldProps;
    fields: IFormFieldProps[];
}

interface IFESortingProps extends IFESortingState {
    onValueChanged(key: string, value: string, errorMessage?: string): void;
}

export default class FESorting extends React.Component<IFESortingProps, IFESortingState> {
    private _dragDropEvents: IDragDropEvents;
    private _draggedItem: any;

    constructor(props: IFESortingProps) {
        super(props);

        let { field, fields } = this.props;
        this._dragDropEvents = this._getDragDropEvents();
        this.state = {
            field,
            fields
        }
    }

    private _getDragDropEvents = (): IDragDropEvents => {
        return {
            canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
                return true;
            },
            canDrag: (item?: any) => {
                return true;
            },
            onDrop: (item?: any, event?: DragEvent) => {
                let { field } = this.state;
                if (item) {
                    let data: any = field.value;
                    let newIndex = data.findIndex((d: any) => { return d.key === item.key });
                    data = data.filter((v: any) => { return v.key !== this._draggedItem.key; });
                    data.splice(newIndex, 0, this._draggedItem);
                    this.props.onValueChanged(field.key, data);
                }
            },
            onDragStart: (item?: any) => {
                this._draggedItem = item;
            },
            onDragEnd: (item?: any, event?: DragEvent) => {
                this._draggedItem = undefined;
            }
        };
    }

    public componentWillReceiveProps(props: IFESortingProps) {
        this.setState({ field: props.field });
    }

    public render() {
        let { field } = this.state;
        let data: { key: string; text: string; }[] = field.value || [];

        return (
            <Stack tokens={{ childrenGap: 5 }}>
                <Stack.Item>
                    <FieldHeader title={field.title} required={field.isRequired} content={field.content} />
                </Stack.Item>
                {
                    field.value && field.value.length > 0 ? (
                        <Stack.Item>
                            <DetailsList
                                items={data}
                                selectionMode={SelectionMode.none}
                                columns={[
                                    { key: "text", fieldName: "text", name: "text", isPadded: false, isIconOnly: true, minWidth: 10, maxWidth: 10, onRender: (item: any) => { return <Icon iconName={"Sort"} />; } },
                                    { key: "text", fieldName: "text", name: "text", minWidth: 0 }
                                ]}
                                isHeaderVisible={false}
                                dragDropEvents={this._dragDropEvents} />
                        </Stack.Item>
                    ) : null
                }
                <Stack.Item>
                    <FieldFooter error={field.errorMessage} description={field.description} />
                </Stack.Item>
            </Stack>
        );
    }
}