import React from 'react';

// import interfaces
import {IFormFieldProps} from './../formProps';

// import subcomponents
import FieldHeader from './../sub/fieldHeader';
import FieldFooter from './../sub/fieldFooter';

// import services
import Validation from './../services/validation';

// import fabric ui components
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';

interface IFEToggleState {
    field: IFormFieldProps;
    fields: IFormFieldProps[];
}

interface IFEToggleProps extends IFEToggleState {
    onValueChanged(key:string, value:boolean, errorMessage?:string): void;
}

export default class FEToggle extends React.Component<IFEToggleProps, IFEToggleState> {
    constructor(props:IFEToggleProps) {
        super(props);

        let {field, fields} = this.props;
        this.state = {
            field,
            fields
        }
    }

    public componentWillReceiveProps(props:IFEToggleProps) {
        this.setState({field: props.field});
    }

    private _onToggleChanged = (evt?:any, checked?:boolean) => {
        let {field, fields} = this.state;
        let error = Validation.field(field, checked, fields);
        this.props.onValueChanged(field.key, checked || false, error);
    }

    public render() {
        let {field} = this.state;
        return (
            <Stack tokens={{childrenGap: 5}}>
                <Stack.Item>
                    <FieldHeader title={field.title} required={field.isRequired} content={field.content}/>
                </Stack.Item>
                <Stack.Item>
                    <Toggle disabled={field.isDisabled} onText={field.toggleConfiguration ? field.toggleConfiguration.onText : ""} offText={field.toggleConfiguration ? field.toggleConfiguration.offText : ""} checked={field.value} onChange={this._onToggleChanged}/>
                </Stack.Item>
                <Stack.Item>
                    <FieldFooter error={field.errorMessage} description={field.description}/>
                </Stack.Item>
            </Stack>
        );
    }
}