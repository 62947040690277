import { v4 as uuid } from 'uuid';
import Request from './request';

// import interfaces
import { IIntakeDataProps } from './../props/data';

export default class KEPService {
    public async getById(id:string):Promise<IIntakeDataProps | undefined> {
        try {
            let results:any = await Request.get(`/api/intake/${id}`);
            let relatedIntake:IIntakeDataProps = results.data.data;
            return relatedIntake;
        } catch(e) {
            throw(e);
        }
    }

    public async getAll(active?:boolean):Promise<IIntakeDataProps[]> {
        try {
            let results:any = await Request.get(`/api/intake${active != undefined ? `?active=${active ? 1 : 0}` : ""}`);
            let intakes:IIntakeDataProps[] = results.data.data;
            return intakes;
        } catch(e) {
            throw(e);
        }
    }

    public async create(data:IIntakeDataProps):Promise<IIntakeDataProps> {
        try {
            data.id = uuid();
            let result:any = await Request.post("/api/intake", data);
            return result.data.data as IIntakeDataProps;
        } catch(e) {
            throw(e);
        }
    }

    public async update(id:string, data:IIntakeDataProps) {
        try {
            delete data.id;
            await Request.patch(`/api/intake/${id}`, data);
            return data;
        } catch(e) {
            throw(e);
        }
    }

    public async delete(data:IIntakeDataProps) {
        try {
            await Request.delete(`/api/intake/${data.id}`);
            return data;
        } catch(e) {
            throw(e);
        }
    }
}