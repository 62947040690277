import React from 'react';

// import styles
import kepInternalStyle from './../../../styles/kepinternal.module.scss';

// import fabric ui components
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Text } from 'office-ui-fabric-react/lib/Text';
interface IFieldFooterProps {
    error?: string;
    description?: string;
}

export default class FieldFooter extends React.Component<IFieldFooterProps, IFieldFooterProps> {
    constructor(props:IFieldFooterProps) {
        super(props);

        this.state = this.props;
    }

    public componentWillReceiveProps(props:IFieldFooterProps) {
        this.setState({...props});
    }

    public render() {
        let {error, description} = this.state;
        return (
            <Stack>
                {description ? <Stack.Item><Text>{description}</Text></Stack.Item> : null}
                {error ? <Stack.Item><Text variant={"small"} className={kepInternalStyle.errorText}>{error}</Text></Stack.Item> : null}
            </Stack>
        );
    }
}