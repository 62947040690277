import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

// import pages
import LoginPage from './pages/login/login';
import LogoutPage from './pages/logout/logout';
import HomePage from './pages/home/home';

// import services
import AuthenticationService from './services/authentication';
import UserService from './services/user';

// import general styling
import './styles/general.scss';

// import fabric ui
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

interface IAppProps {}
interface IAppState {
  checkingCredential: boolean;
  loggedIn?: boolean;
}

declare var window:any;
export default class App extends React.Component<IAppProps, IAppState> {
  private authenticationService:AuthenticationService = new AuthenticationService();
  private userService:UserService = new UserService();

  constructor(props:IAppProps) {
      super(props);
      this.state = {
        checkingCredential: true
      }
  }

  public componentWillMount = async () => {
      try {
          // check current user token
          await this.authenticationService.checkLogin();

          // get current user
          let currentUser = await this.userService.getCurrentUser();
          window.getCurrentUser = function() {
              return currentUser;
          };

          this.setState({checkingCredential: false, loggedIn: true});
      } catch(e) {
        if (window.location.pathname !== '/login' && window.location.pathname !== '/logout') {
          window.location.href = "/login";
        }
        this.setState({checkingCredential: false, loggedIn: false});
      }
  }

  public render() {
    return (
      <Stack>
        {this.state.checkingCredential ? <Spinner label="Harap tunggu ..." labelPosition="bottom" size={SpinnerSize.large} style={{marginTop: 20}} /> : null}
        {
            !this.state.checkingCredential ? (
              <Router>
                <Route exact path="/login" component={LoginPage}/>
                <Route exact path="/logout" component={LogoutPage}/>
                {this.state.loggedIn ? <Route path="/" component={HomePage}/> : null}
              </Router>
            ) : null
        }
      </Stack>
    );
  }
}
