import React from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';

// import service
import GeneralService from './../../services/general';

// import styles
import homePageStyles from './home.module.scss';

// import components
import Menubar from './../../components/menubar/menubar';

// import pages
import DashboardPage from './../dashboard/dashboard';
import KEPPage from './../kep/kep';
import ClassPage from './../class/class';
import ModulePage from './../module/module';
import LessonPage from './../lesson/lesson';
import UserPage from './../user/user';
import EventPage from './../event/event';
import RandomisePage from './../randomise/randomise';
import TeacherSchedulePage from './../teacherSchedule/teacherSchedule';
import SchedulePage from './../schedule/schedule';
import MyAccount from './../myaccount/myAccount';
import Intake from './../intake/intake';

// import development pages
import GenerateTeacherLessonData from './../../development/generateTeacherLessonConnection';

// import fabric ui
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

// local interfaces
interface IHomePageProps {}
interface IHomePageState {
    checkingCredential: boolean;
}

declare var window:any;
export default class HomePage extends React.Component<IHomePageProps, IHomePageState> {
    constructor(props: IHomePageProps) {
        super(props);
    }

    public render() {
        return (
            <Stack className={homePageStyles.home}>
                <Router>
                    <Stack.Item>
                        <Menubar />
                    </Stack.Item>
                    <Stack.Item>
                        <Switch>
                            <Route path="/beranda">
                                <Stack.Item className={homePageStyles.dashboardContainer}><DashboardPage /></Stack.Item>
                            </Route>
                            <Route path="/kep">
                                <Stack.Item className={homePageStyles.contentContainer}><KEPPage /></Stack.Item>
                            </Route>
                            <Route path="/kelas">
                                <Stack.Item className={homePageStyles.contentContainer}><ClassPage /></Stack.Item>
                            </Route>
                            <Route path="/modul">
                                <Stack.Item className={homePageStyles.contentContainer}><ModulePage /></Stack.Item>
                            </Route>
                            <Route path="/pelajaran">
                                <Stack.Item className={homePageStyles.contentContainer}><LessonPage /></Stack.Item>
                            </Route>
                            <Route path="/pengguna">
                                <Stack.Item className={homePageStyles.contentContainer}><UserPage /></Stack.Item>
                            </Route>
                            <Route path="/acara">
                                <Stack.Item className={homePageStyles.contentContainer}><EventPage /></Stack.Item>
                            </Route>
                            <Route path="/jadwal">
                                <Stack.Item className={homePageStyles.contentContainer}><SchedulePage /></Stack.Item>
                            </Route>
                            {
                                GeneralService.isCurrentUserAdmin() || GeneralService.isCurrentUserSecretary() ? <Route path="/pengacakan">
                                    <Stack.Item className={homePageStyles.contentContainer}><RandomisePage /></Stack.Item>
                                </Route> : null
                            }
                            <Route path="/jadwalGuru">
                                <Stack.Item className={homePageStyles.contentContainer}><TeacherSchedulePage /></Stack.Item>
                            </Route>
                            <Route path="/akun">
                                <Stack.Item className={homePageStyles.contentContainer}><MyAccount /></Stack.Item>
                            </Route>
                            <Route path="/tahunPelajaran">
                                <Stack.Item className={homePageStyles.contentContainer}><Intake /></Stack.Item>
                            </Route>
                        </Switch>
                    </Stack.Item>
                </Router>
            </Stack>
        );
    }
}
