import { IUserDataProps } from './../props/data';

let users:IUserDataProps[] = [
    {
        id: "ADMIN001",
        name: "Aldvin Tumbelaka",
        email: "atumbelaka@gmail.com",
        roles: ["Administrator", "Sekretariat"],
        includedKEPId: ["yakobussurabaya"],
        maxLessonsPerWeek: 3,
        city: "Surabaya",
    },
    {
        id: "ADMIN002",
        name: "Meliana Tumbelaka",
        email: "mtumbelaka@gmail.com",
        roles: ["Administrator", "Sekretariat"],
        includedKEPId: ["yakobussurabaya"],
        maxLessonsPerWeek: 3,
        city: "Surabaya",
    },
    {
        id: "ADMIN003",
        name: "Jovita Tirtowidjaja",
        email: "jtirtowidjaja@gmail.com",
        roles: ["Administrator", "Sekretariat"],
        maxLessonsPerWeek: 3,
        includedKEPId: ["yakobussurabaya"],
        city: "Surabaya",
    },
    {
        "id": "d9ae30fc-db55-4185-8979-cc942976e3fe",
        "name": "VINCENTIUS A PAULO  DIPOYUGO",
        "city": "Surabaya",
        "email": "vincent.dipojugo@gmail.com",
        includedKEPId: ["yakobussurabaya"],
        maxLessonsPerWeek: 3,
        "roles": [
            "Guru"
        ],
        "notes": "Mengajar hanya di SBY, seminggu maks 1x"
    },
    {
        "id": "f9ac7dab-53b2-4a5f-9846-a17080be62bd",
        includedKEPId: ["yakobussurabaya"],
        maxLessonsPerWeek: 3,
        "name": "M. H. M. JUDY NURADI",
        "city": "Surabaya",
        "email": "judynuradi@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "324d5861-ed90-4246-912f-2ff91812b2ee",
        includedKEPId: ["yakobussurabaya"],
        maxLessonsPerWeek: 3,
        "name": "MEIDY MARINGKA",
        "city": "Surabaya",
        "email": "m_maringka@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "4f1a0a9c-eea4-49e5-8100-2788c0bbcbdf",
        includedKEPId: ["yakobussurabaya"],
        "name": "M. G. NINIEK DHARMAYANTI A.",
        maxLessonsPerWeek: 3,
        "city": "Surabaya",
        "email": "niniek_trawas@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "f42c9a79-f28e-48ca-b3ce-4ac0f60e3257",
        includedKEPId: ["yakobussurabaya"],
        maxLessonsPerWeek: 3,
        "name": "H. L. TJENDANA  WIJAYA",
        "city": "Surabaya",
        "email": "tjendana@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "9cb3f21f-fa1f-4e91-bbe2-e6ddb41afda0",
        includedKEPId: ["yakobussurabaya"],
        maxLessonsPerWeek: 3,
        "name": "JOSEPHA WIBOWO",
        "city": "Surabaya",
        "email": "josepharatnadewati@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": "Tidak mengajar ME lagi"
    },
    {
        "id": "2238409c-6346-45a3-8e4e-31c2dbe0cf41",
        includedKEPId: ["yakobussurabaya"],
        maxLessonsPerWeek: 3,
        "name": "M. G. M. GRACE MARCELLINUS",
        "city": "Surabaya",
        "email": "graceliem@ymail.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "5c3b1b1e-5d4d-4e69-84c4-1b4e51b72042",
        includedKEPId: ["yakobussurabaya"],
        "name": "FRANSISCUS XAVERIUS  SANTOSO T.",
        "city": "Surabaya",
        "email": "f.xavier83@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "135ecf91-b9e6-4128-bf2d-067d8c948c46",
        includedKEPId: ["yakobussurabaya"],
        "name": "M. F. BETTY DIANTORO",
        "city": "Surabaya",
        "email": "bettydiantoro@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "09642a68-33a4-4284-ac3b-afac7dd953a4",
        includedKEPId: ["yakobussurabaya"],
        "name": "T. M. IDA TJOKROSUDIBJO",
        "city": "Surabaya",
        "email": "idatjokro@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": "Tidak mengajar ME lagi"
    },
    {
        "id": "d9e309fe-db6a-46ea-8280-644e81d04c3c",
        includedKEPId: ["yakobussurabaya"],
        "name": "M. T. WINDIYATI NUGROHO",
        "city": "Surabaya",
        "email": "windiyati@pacificbeautygroup.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "04d30dbb-55aa-4bda-aba9-c00fed2375c1",
        includedKEPId: ["yakobussurabaya"],
        "name": "V. BUDI PURWANTO",
        "city": "Surabaya",
        "email": "vinsentbudi@yahoo.co.id",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "bd879068-d79b-490e-b26e-d4909cc99948",
        includedKEPId: ["yakobussurabaya"],
        "name": "A.  SETIADI SANTOSO",
        "city": "Surabaya",
        "email": "setiadisantoso@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "7673a9ad-ea11-4641-b688-8d0b289b22b3",
        includedKEPId: ["yakobussurabaya"],
        "name": "JERONIMA TH. INGE",
        "city": "Surabaya",
        "email": "jeromstheo@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "fb20b853-0d87-429b-b685-0c1bb615611d",
        includedKEPId: ["yakobussurabaya"],
        "name": "M. I. SRI WARNANINGSIH",
        "city": "Surabaya",
        "email": "sriwarna@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "6c18cd7e-1c2b-43b6-aaba-e0b150f5b285",
        includedKEPId: ["yakobussurabaya"],
        "name": "FRANSISCUS XAVERIUS SATRIO HUTOMO",
        "city": "Surabaya",
        "email": "frhutomo@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "cd440aeb-c5e4-4951-8d98-c885a09ce724",
        includedKEPId: ["yakobussurabaya"],
        "name": "F. HERMAN MAHENDRA",
        "city": "Surabaya",
        "email": "hermanmahendra@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "3620a4c3-3d17-45f1-a230-937a71464b71",
        includedKEPId: ["yakobussurabaya"],
        "name": "VINCENTIUS A.K.  SURYANANDA HOSEIN",
        "city": "Surabaya",
        "email": "vsuryah@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "cee1cd3b-97c5-42fb-babf-ed9a699a2c8f",
        includedKEPId: ["yakobussurabaya"],
        "name": "MARTINUS HERRIAWAN PRAJOGO",
        "city": "Surabaya",
        "email": "martinus.herriawan@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "f6884e40-b454-420d-aacb-2d1db06dc0e0",
        includedKEPId: ["yakobussurabaya"],
        "name": "CHRISTIEN KESUMADJAJA TANAMAL",
        "city": "Surabaya",
        "email": "kesumadjaja@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "239d0842-30ef-40f0-a657-e71804ae8d7d",
        includedKEPId: ["yakobussurabaya"],
        "name": "R. Ch. ENDANG H.",
        "city": "Surabaya",
        "email": "endanghariwatispd@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": "Tidak Tahu"
    },
    {
        "id": "dc6a7ebc-0bfa-43cc-9372-9b6e1ce89263",
        includedKEPId: ["yakobussurabaya"],
        "name": "MARIA YULIA",
        "city": "Surabaya",
        "email": "mariahutomo@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "d2317d45-3766-4530-98ee-ac1541276878",
        includedKEPId: ["yakobussurabaya"],
        "name": "R. M. PRATIWI PUTRI TJAHJONO",
        "city": "Surabaya",
        "email": "pratiwiputri@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "89ee8d77-81c7-4c6f-bff8-68d7a06596e3",
        includedKEPId: ["yakobussurabaya"],
        "name": "FRANSISKA WIJAYANTI",
        "city": "Surabaya",
        "email": "oei.fransiska@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "618b39d4-2280-42a2-ba9d-83b31b6e138b",
        includedKEPId: ["yakobussurabaya"],
        "name": "F. X. SATRIA WIRAKUSUMA",
        "city": "Surabaya",
        "email": "satriawirakusuma@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": "Tidak mengajar ME lagi"
    },
    {
        "id": "3ec3a8bc-e546-414f-8343-d95defa33737",
        includedKEPId: ["yakobussurabaya"],
        "name": "E. CHRISTINE MUKTI WULANDARI",
        "city": "Surabaya",
        "email": "christine.mw896@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "f2a29d42-f5a7-4ada-b38f-0bc4e94419a6",
        includedKEPId: ["yakobussurabaya"],
        "name": "V. JANUAR  WIDJAJA SUBAGIO",
        "city": "Surabaya",
        "email": "januarwidjaja@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "238fdf5a-225a-4cd9-8a6c-5836d7ded890",
        includedKEPId: ["yakobussurabaya"],
        "name": "CHRISTINE TJITRA SRIWULAN",
        "city": "Surabaya",
        "email": "lani.irawan@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "f40a286d-93dd-4d13-8e77-36b36255e684",
        includedKEPId: ["yakobussurabaya"],
        "name": "CHATARINA TAN HWEE WEN",
        "city": "Surabaya",
        "email": "hweewen88@yahoo.co.id",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "2b3ad392-a85b-4f8d-9ff4-0c98a831b297",
        includedKEPId: ["yakobussurabaya"],
        "name": "MARIA INEKE IRAWATI",
        "city": "Surabaya",
        "email": "inkjls@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "98a83131-585f-4063-a699-11b3ade8c9f9",
        includedKEPId: ["yakobussurabaya"],
        "name": "A.R. LILY FEBRITANIA",
        "city": "Surabaya",
        "email": "lilyfebri66@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "87f0ee98-0243-4bef-a432-47185f3aa768",
        "name": "DAVID BUDIONO",
        includedKEPId: ["yakobussurabaya"],
        "city": "Surabaya",
        "email": "davidwu74@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "63164783-cbf5-4bfc-80ca-210b1cceba50",
        includedKEPId: ["yakobussurabaya"],
        "name": "THOMAS FERRY SURYANTO",
        "city": "Surabaya",
        "email": "ferrysurjanto@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "18c5b4ad-a652-4ed6-bf31-4eee1d6f5909",
        includedKEPId: ["yakobussurabaya"],
        "name": "VINCENTIUS MARTIN GUNAWAN",
        "city": "Surabaya",
        "email": "indigo_design@rocketmail.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "369179ef-716d-4b3f-be58-20dc7e84053c",
        "name": "ALEXANDER MARIA PRIHADI KARTYASA J.",
        includedKEPId: ["yakobussurabaya"],
        "city": "Surabaya",
        "email": "gabyglenn@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": "Baru"
    },
    {
        "id": "ccb86608-7677-46ae-95a3-c6370a147a01",
        "name": "ANGELA MARIA RUKMINI KARJADI",
        includedKEPId: ["yakobussurabaya"],
        "city": "Surabaya",
        "email": "angela.karjadi@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": "Baru"
    },
    {
        "id": "c046227e-8c16-4a6b-b4ed-12d1c1930640",
        "name": "ANTONIUS BUDI DARMAWAN H.",
        includedKEPId: ["yakobussurabaya"],
        "city": "Surabaya",
        "email": "bdh777777@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": "Baru"
    },
    {
        "id": "6c976178-d153-483f-9be1-2bb5a382c656",
        "name": "FRANZESCA PAULINA INDRAWATI DJAPRI",
        "city": "Surabaya",
        includedKEPId: ["yakobussurabaya"],
        "email": "idj_ganadhi@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": "Baru"
    },
    {
        "id": "f45169cd-28e3-4792-9ac1-9471d3a29fe9",
        "name": "IMELDA MIMIJANTI HUTOMO",
        includedKEPId: ["yakobussurabaya"],
        "city": "Surabaya",
        "email": "mimijanti08@yahoo.co.id",
        "roles": [
            "Guru"
        ],
        "notes": "Baru"
    },
    {
        "id": "dc53b085-a693-4e30-8fc1-8ed7cd153e11",
        "name": "KRESENSIA LIVIA CHANDRASARI",
        includedKEPId: ["yakobussurabaya"],
        "city": "Surabaya",
        "email": "livia_chandra_sari@hotmail.com",
        "roles": [
            "Guru"
        ],
        "notes": "Baru"
    },
    {
        "id": "12487cde-590f-4fe6-9717-a78190d6d7d1",
        includedKEPId: ["yakobussurabaya"],
        "name": "LUCIA LILIANA",
        "city": "Surabaya",
        "email": "lucia_lilianaliem@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": "Baru"
    },
    {
        "id": "94e3e7ca-c15c-4d5d-bed9-2fe0980fba34",
        includedKEPId: ["yakobussurabaya"],
        "name": "MICHEL EDWARD NAJOAN - EWA",
        "city": "Surabaya",
        "email": "ewa4@hotmail.com",
        "roles": [
            "Guru"
        ],
        "notes": "Baru"
    },
    {
        "id": "547020bb-bb82-403e-b5e2-cb11ab0676aa",
        includedKEPId: ["yakobussurabaya"],
        "name": "RUFINA MARIA WIJANI TJENDRO",
        "city": "Surabaya",
        "email": "wijani123@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": "Baru"
    },
    {
        "id": "3dc4b7af-145a-44bc-bf46-aa9bba2e4478",
        includedKEPId: ["yakobussurabaya"],
        "name": "STEPHANUS ANORAGA (STEVE)",
        "city": "Surabaya",
        "email": "steve.anoraga@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": "Baru"
    },
    {
        "id": "26878a5b-6a20-486c-b366-a55af8cd37c7",
        includedKEPId: ["yakobussurabaya"],
        "name": "VERONIKA YENNI SRIAWATY",
        "city": "Surabaya",
        "email": "silverdust0674@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": "Baru"
    },
    {
        "id": "c4da8add-afcf-496e-a2eb-88e70858ac81",
        includedKEPId: ["yakobussurabaya"],
        "name": "MARIA POERIANI SOEKOWARDANI",
        "city": "Surabaya",
        "email": "mp.soekowardani63@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": "Belum Mengajar"
    },
    {
        "id": "c3416e6e-193c-4d1f-8278-0368c6ce562a",
        "name": "VINCENTIA VALENTINA DEWI LINGGAWATI",
        includedKEPId: ["yakobussurabaya"],
        "city": "Surabaya",
        "email": "dewi_linggawati@APP.co.id",
        "roles": [
            "Guru"
        ],
        "notes": "Belum Mengajar"
    },
    {
        "id": "97ba8c1b-cc72-4d23-a9ef-f7f3ebdd3525",
        "name": "MARCELLUS ARDIAN RAHMANTO BUDIONO",
        includedKEPId: ["yakobussurabaya"],
        "city": "Surabaya",
        "email": "marcell1310@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": "Baru"
    },
    {
        "id": "fad337ba-0c31-4846-8f7c-c024332398a9",
        "name": "STEPHANUS MICHAEL HARIJANTO",
        "city": "Surabaya",
        includedKEPId: ["yakobussurabaya"],
        "email": "michael_harijanto@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": "Mengajar hanya di Kediri, Jombang, Nganjuk"
    },
    {
        "id": "d555cc63-c7b3-469c-a83b-d71268ae92e4",
        "name": "MARIA FRANSISCA KUMALASARI",
        includedKEPId: ["yakobussurabaya"],
        "city": "Surabaya",
        "email": "SKumalasari246@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": "Mengajar hanya di Kediri, Jombang, Nganjuk"
    },
    {
        "id": "ccc00e9f-352e-4812-a863-51cc240f80e6",
        "name": "MARIA LAURENSIA ERLIN NURHAYATI",
        includedKEPId: ["yakobussurabaya"],
        "city": "Surabaya",
        "email": "erlin_bromo@yahoo.co.id",
        "roles": [
            "Guru"
        ],
        "notes": "Mengajar hanya di Kediri, Jombang, Nganjuk"
    },
    {
        "id": "41cf711d-a995-4097-b70c-5a82145b1f82",
        "name": "LILIEK KUSMINDARTY MARIA EVELINA",
        includedKEPId: ["yakobussurabaya"],
        "city": "Surabaya",
        "email": "liliek_supiyanto@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": "Mengajar hanya di Kediri, Jombang, Nganjuk"
    },
    {
        "id": "9dd9bd82-36fc-48ca-aa0e-d093457760b8",
        "name": "HIACINTHA CAECILIA LIEKE KUSUMADEWI",
        includedKEPId: ["yakobussurabaya"],
        "city": "Surabaya",
        "email": "lieke.kusumadewi@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": "Mengajar hanya di Kediri, Jombang, Nganjuk"
    },
    {
        "id": "53adb6b1-094f-4305-b4a4-b591f82ab7cd",
        "name": "VERONICA MARIA HENNY FARANITA SALIM",
        includedKEPId: ["yakobussurabaya"],
        "city": "Surabaya",
        "email": "hennyfs21@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": ""
    },
    {
        "id": "df2943c3-541a-4535-a1be-ea9ee568fbbf",
        "name": "FRANSISKUS XAVERIUS GIANTO",
        includedKEPId: ["yakobussurabaya"],
        "city": "Surabaya",
        "email": "gianto_f@yahoo.co,id",
        "roles": [
            "Guru"
        ],
        "notes": "Mengajar hanya di Kediri, Jombang, Nganjuk"
    },
    {
        "id": "86008351-7523-4038-90cd-d42a5cd48cad",
        "name": "DEBORA LILIK DAHLIA",
        "city": "Surabaya",
        "email": "deboralilikdaliah@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": "Mengajar hanya di Kediri, Jombang, Nganjuk"
    },
    {
        "id": "849ab3b6-c66f-41da-a71f-ba06b397a8cd",
        "name": "CHRISTINA ERLI INDRIASTUTI",
        "city": "Surabaya",
        "email": "erlindriapk@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": "Mengajar hanya di Kediri, Jombang, Nganjuk"
    },
    {
        "id": "a6d4dc50-35b2-4a73-9e34-61e1b4fc7528",
        "name": "AGUSTINUS JOKO SUSILO",
        "city": "Surabaya",
        "email": "",
        "roles": [
            "Guru"
        ],
        "notes": "Mengajar hanya di Kediri, Jombang, Nganjuk"
    },
    {
        "id": "713684ac-4b64-4a8b-ac6a-3ef7492cd5aa",
        "name": "MARCELINUS YOSAPHAT MARSUDI",
        "city": "Surabaya",
        "email": "drsmarsudi63@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": "Mengajar hanya di Kediri, Jombang, Nganjuk"
    },
    {
        "id": "99e4d842-b07c-4857-a45c-60bc2c12110e",
        "name": "LAURENTIA IRA JULIA WAHJUDI",
        "city": "Surabaya",
        "email": "ivo_cute_2006@yahoo.co.id",
        "roles": [
            "Guru"
        ],
        "notes": "Mengajar hanya di Kediri, Jombang, Nganjuk"
    },
    {
        "id": "9ceb9e92-c3d1-4952-b878-729837d9c466",
        "name": "MARCELLA VINCENTIA LILIANA DEWI",
        "city": "Surabaya",
        "email": "dewilia980@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": "Mengajar hanya di Kediri, Jombang, Nganjuk"
    },
    {
        "id": "e7e55b1b-7d76-435e-ba7a-e2b96578232e",
        "name": "M. T. ELEINE MAGDALENA",
        "city": "Surabaya",
        "email": "eleinemagdalena@gmail.com",
        "roles": [
            "Guru"
        ],
        "notes": "Bantuan, kalau diperlukan, Tidak keluar kota"
    },
    {
        "id": "a97d919d-e7ff-4ec1-af30-457ddc5735af",
        "name": "STEFFI REGINA NATALIA LEONIE",
        "city": "Surabaya",
        "email": "leoniedjohan65@yahoo.co.id",
        "roles": [
            "Guru"
        ],
        "notes": "Bantuan, Tidak terlalu banyak dijadwal, khusu Probolinggo, Jember bab 1"
    },
    {
        "id": "010585e2-64d9-4288-ae7d-e3aa0506f19d",
        "name": "FERDINANDUS HANS SEBASTIAN",
        "city": "Surabaya",
        "email": "hans_seb87@yahoo.com",
        "roles": [
            "Guru"
        ],
        "notes": "Bantuan, Tidak terlalu banyak dijadwal."
    }
];

export default users;