import React from 'react';

// import interfaces
import {IFormFieldProps} from './formProps';

// import all fields
import FETextField from './fields/textfield';
import FEPassword from './fields/password';
import FETextArea from './fields/textarea';
import FEDropdown from './fields/dropdown';
import FERadioButton from './fields/radiobutton';
import FESpinButton from './fields/spinbutton';
import FESorting from './fields/sorting';
import FEDatePicker from './fields/datepicker';
import FEMultiSelectDropdown from './fields/multiselectdropdown';
import FECustom from './fields/custom';
import FENumberField from './fields/numberfield';
import FEToggle from './fields/toggle';

// import service
import FormMetadataService from './services/metadata';

// import fabric ui components
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

interface IFormComponentProps {
    fields: IFormFieldProps[];
    defaultValue?: any;
    onValueChanged(fields:IFormFieldProps[]): void;
}

interface IFormComponentState {
    fields: IFormFieldProps[];
    loading: boolean;
}

export default class Form extends React.Component<IFormComponentProps, IFormComponentState> {
    constructor(props:IFormComponentProps) {
        super(props);

        let {fields} = this.props;
        this.state = {
            fields,
            loading: true
        }
    }

    public async componentWillMount() {
        let {fields, defaultValue} = this.props;
        if (defaultValue) {
            fields = await FormMetadataService.populate(fields, defaultValue);
        }
        this.setState({fields, loading: false});
    }

    public async componentWillReceiveProps(props:IFormComponentProps) {
        let {fields, defaultValue} = props;
        if (defaultValue) {
            fields = await FormMetadataService.populate(fields, defaultValue);
        }
        this.setState({fields});
    }

    private _onValueChanged = async (key:string, value:any, errorMessage?:string) => {
        let {fields} = this.state;
        let relatedField = fields.find((field) => {return field.key === key;});
        if (relatedField) {
            relatedField.value = value;
            relatedField.errorMessage = errorMessage;
        }
        this.props.onValueChanged(fields);
        this.setState({fields});
    }

    private renderField = (field:IFormFieldProps) => {
        if (field.type === 'textfield') {
            return <FETextField field={field} fields={this.state.fields} onValueChanged={this._onValueChanged}/>;
        } else if (field.type === 'password' || field.type === 'repeatPassword') {
            return <FEPassword field={field} fields={this.state.fields} onValueChanged={this._onValueChanged}/>;
        } else if (field.type === 'number') {
            return <FENumberField field={field} fields={this.state.fields} onValueChanged={this._onValueChanged}/>;
        } else if (field.type === 'textarea') {
            return <FETextArea field={field} fields={this.state.fields} onValueChanged={this._onValueChanged}/>;
        } else if (field.type === 'dropdown') {
            return <FEDropdown field={field} fields={this.state.fields} onValueChanged={this._onValueChanged}/>;
        } else if (field.type === 'radiobutton') {
            return <FERadioButton field={field} fields={this.state.fields} onValueChanged={this._onValueChanged}/>;
        } else if (field.type === 'spinbutton') {
            return <FESpinButton field={field} fields={this.state.fields} onValueChanged={this._onValueChanged}/>;
        } else if (field.type === 'multiselectdropdown') {
            return <FEMultiSelectDropdown field={field} fields={this.state.fields} onValueChanged={this._onValueChanged}/>;
        } else if (field.type === 'sorting') {
            return <FESorting field={field} fields={this.state.fields} onValueChanged={this._onValueChanged}/>;
        } else if (field.type === 'datepicker') {
            return <FEDatePicker field={field} fields={this.state.fields} onValueChanged={this._onValueChanged}/>;
        } else if (field.type === 'custom') {
            return <FECustom field={field} fields={this.state.fields} onValueChanged={this._onValueChanged}/>;
        } else if (field.type === 'toggle') {
            return <FEToggle field={field} fields={this.state.fields} onValueChanged={this._onValueChanged}/>;
        } else {
            return null;
        }
    }

    public render() {
        let {loading, fields} = this.state;
        return (
            <Stack tokens={{childrenGap: 15}}>
                {loading ? <Spinner label={"Memuat form ..."} size={SpinnerSize.medium} labelPosition={"bottom"}/> : null}
                {
                    !loading ? (
                        fields.filter((field) => {return !field.isHidden;}).map(field => {
                            return <Stack.Item>{this.renderField(field)}</Stack.Item>;
                        })
                    ) : null
                }
            </Stack>
        );
    }
}