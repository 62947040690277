import React from 'react';

// import styles
import kepInternalStyles from './../../styles/kepinternal.module.scss';

// import interfaces
import { IUserDataProps, TUserRoleType } from './../../props/data';
import { IListPanelClosed } from './../../props/general';

// import services
import UserService from './../../services/user';

// import components
import UserPropsPanel from './userPropsPanel';
import ResetPasswordPanel from './resetPasswordPanel';

// import fabric ui
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { DetailsList, IColumn, SelectionMode, DetailsListLayoutMode } from 'office-ui-fabric-react/lib/DetailsList';

// local interfaces
interface ITeacherListProps {
    role: TUserRoleType;
    listColumns: IColumn[];
    includeCommandBar: boolean;
    hasActionButton: boolean;
    allowUpdate?: boolean;
    allowDelete?: boolean;
    allowResetPassword?: boolean;
    allowCreate?: boolean;
}

interface ITeacherListState {
    keyword: string;
    commandBar: {
        leftItems?: ICommandBarItemProps[];
        rightItems?: ICommandBarItemProps[];
    };
    messageBar?: {text: string; type: MessageBarType};
    data: IUserDataProps[];
    shownData: IUserDataProps[];
    selectedData?: IUserDataProps[];
    loading?: boolean;
    listColumns: IColumn[];
    components: {
        TeacherPropsPanelOpened?: boolean;
        ResetUserPanelOpened?: boolean;
    };
}

export default class TeacherList extends React.Component<ITeacherListProps, ITeacherListState> {
    private userService:UserService = new UserService();

    constructor(props: ITeacherListProps) {
        super(props);

        this.state = {
            keyword: "",
            commandBar: {},
            data: [],
            shownData: [],
            loading: true,
            listColumns: [],
            components: {}
        }
    }

    public async componentWillMount() {
        let leftItems:ICommandBarItemProps[] = [
            {
                key: 'searchUser',
                onRender: () => {
                    return (
                        <SearchBox className={kepInternalStyles.searchBox} onChange={this._onSearch} placeholder="Cari nama/email/domisili ..."/>
                    );
                }
            }
        ];

        if (this.props.allowCreate) {
            leftItems = [...leftItems, ...[
                    {
                        key: 'newUser',
                        text: 'Tambah pengguna baru',
                        iconProps: { iconName: 'Add' },
                        onClick: () => {
                            this.setState({selectedData: undefined, components:{TeacherPropsPanelOpened: true}});
                        }
                    }
                ]
            ]
        }

        let data = await this.userService.getAll(this.props.role);

        let listColumns:IColumn[] = this.props.listColumns;
        if (this.props.hasActionButton && (this.props.allowDelete || this.props.allowResetPassword || this.props.allowUpdate)) {
            listColumns.splice(1, 0, {
                key: 'actionButtons',
                name: '',
                minWidth: 20,
                maxWidth: 20,
                isIconOnly: true,
                className: kepInternalStyles.listActionButtons,
                onRender: (item?:any) => {
                    let menuItems = [];
                    if (this.props.allowUpdate) {
                        menuItems.push({
                            key: 'updateUser',
                            text: 'Ubah data',
                            iconProps: { iconName: 'Edit' },
                            onClick: () => {
                                this.setState({
                                    selectedData: [item as IUserDataProps],
                                    components:{TeacherPropsPanelOpened: true}
                                });
                            }
                        })
                    }
                    if (this.props.allowDelete) {
                        menuItems.push({
                            key: 'deleteUser',
                            text: 'Hapus pengguna',
                            iconProps: { iconName: 'Delete' },
                            onClick: () => {this.setState({components:{TeacherPropsPanelOpened: true}});}
                        });
                    }
                    if (this.props.allowResetPassword) {
                        menuItems.push({
                            key: 'resetUserPassword',
                            text: 'Reset password',
                            iconProps: { iconName: 'Refresh' },
                            onClick: () => {
                                if (window.confirm(`Apakah anda yakin ingin mengubah password pengguna ${item.name}?`)) {
                                    this.setState({selectedData: [item as IUserDataProps], components:{ResetUserPanelOpened: true}});
                                }
                            }
                        });
                    }
                    return (
                        <IconButton
                            menuProps={{items: menuItems}}
                            title="Tombol aksi"
                            ariaLabel="Tombol aksi"/>
                    );
                }
            });
        }

        this.setState({
            commandBar: {leftItems},
            listColumns,
            data,
            shownData: data,
            loading: false
        });
    }

    private _onSearch = (evt?:any, value?:string) => {
        let key = (value || "").toLowerCase();
        const shownData = this.state.data.filter((d) => {
            return d.name.toLowerCase().indexOf(key) > -1 || 
                    d.email.toLowerCase().indexOf(key) > -1 || 
                    d.city.toLowerCase().indexOf(key) > -1 || 
                    (d.phoneNumber && d.phoneNumber.toLowerCase().indexOf(key) > -1);
        });
        this.setState({shownData, keyword: value || ""});
    }

    private async _onRefreshData() {
        this.setState({loading: true, keyword: ""});
        let data = await this.userService.getAll(this.props.role);
        this.setState({data, shownData: data, loading: false});
    }

    private _onTeacherPropsPanelClosed = async (props?:IListPanelClosed):Promise<void> => {
        this.setState({components: {}});
        if (props && props.refreshData) {this._onRefreshData();}
        if (props && props.messageBar) {this.setState({messageBar: props.messageBar});}
    }

    public render() {
        return (
            <Stack tokens={{childrenGap: 10}}>
                {
                    this.props.includeCommandBar ? (
                        <Stack.Item>
                            <CommandBar
                                items={this.state.commandBar.leftItems || []}
                                farItems={this.state.commandBar.rightItems || []}
                                ariaLabel="Gunakan panah kiri dan kanan untuk berpindah tombol"
                                className={kepInternalStyles.commandBar} 
                                styles={{root:{padding: 0}}}/>
                        </Stack.Item>
                    ) : null
                }
                {
                    this.state.messageBar ? (
                        <Stack.Item>
                            <MessageBar messageBarType={this.state.messageBar.type} isMultiline={true} onDismiss={() => {this.setState({messageBar: undefined})}}>
                                {this.state.messageBar.text}
                            </MessageBar>
                        </Stack.Item>
                    ) : null
                }
                <Stack.Item>
                    {
                        this.state.loading ? (
                            <Spinner label={`Memuat semua  ${this.props.role} ...`} labelPosition="bottom" size={SpinnerSize.large} />
                        ) : null
                    }
                    {
                        !this.state.loading ? (
                            <DetailsList
                                items={this.state.shownData}
                                compact={false}
                                columns={this.state.listColumns}
                                selectionMode={SelectionMode.none}
                                setKey="none"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}/>
                        ) : null
                    }
                    {!this.state.loading && this.state.shownData.length < 1 ? <Text style={{padding: 10}}>Pengguna tidak dapat ditemukan. Tekan tombol "Tambah pengguna baru" untuk membuat pengguna baru.</Text> : null}
                    { this.state.components.TeacherPropsPanelOpened ? <UserPropsPanel data={this.state.selectedData ? this.state.selectedData[0] : undefined} onPanelClosed={this._onTeacherPropsPanelClosed} /> : null }
                    { this.state.components.ResetUserPanelOpened && this.state.selectedData && this.state.selectedData[0] ? <ResetPasswordPanel id={this.state.selectedData[0].id} onPanelClosed={() => {this.setState({components: {}})}} /> : null }
                </Stack.Item>
            </Stack>
        );
    }
}
