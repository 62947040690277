import React from 'react';

// import interfaces
import {IFormFieldProps} from './../formProps';

// import subcomponents
import FieldHeader from './../sub/fieldHeader';
import FieldFooter from './../sub/fieldFooter';

// import services
import Validation from './../services/validation';

// import fabric ui components
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';

interface IFEDropdownState {
    field: IFormFieldProps;
    fields: IFormFieldProps[];
}

interface IFEDropdownProps extends IFEDropdownState {
    onValueChanged(key:string, value?:string | number, errorMessage?:string): void;
}

export default class FEDropdown extends React.Component<IFEDropdownProps, IFEDropdownState> {
    constructor(props:IFEDropdownProps) {
        super(props);

        let {field, fields} = this.props;
        this.state = {
            field,
            fields
        }
    }

    public componentWillReceiveProps(props:IFEDropdownProps) {
        this.setState({field: props.field});
    }

    private _onDropdownChanged = (evt?:any, options?:IDropdownOption) => {
        let {field, fields} = this.state;
        let error = Validation.field(field, options ? options.key : undefined, fields);
        this.props.onValueChanged(field.key, options ? options.key : undefined, error);
    }

    public render() {
        let {field,} = this.state;
        let options:IDropdownOption[] = [];
        if (field.choicesConfiguration) {
            let choices = field.choicesConfiguration.choices || [];
            options = choices.map((choice) => {
                return {key: choice.key, text: choice.text, itemType: choice.itemType};
            });
        }

        return (
            <Stack tokens={{childrenGap: 5}}>
                <Stack.Item>
                    <FieldHeader title={field.title} required={field.isRequired} content={field.content}/>
                </Stack.Item>
                <Stack.Item>
                    <Dropdown disabled={field.isDisabled} options={options} onChange={this._onDropdownChanged} selectedKey={field.value}/>
                </Stack.Item>
                <Stack.Item>
                    <FieldFooter error={field.errorMessage} description={field.description}/>
                </Stack.Item>
            </Stack>
        );
    }
}