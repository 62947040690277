import React from 'react';

// import interfaces
import { IListPanelClosed } from './../../props/general';

// import services
import ModuleService from './../../services/module';
import LessonService from './../../services/lesson';

// import props
import { ILessonDataProps, ILessonTeachersDataProps } from '../../props/data';

// import fabric ui
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { IColumn, DetailsList } from 'office-ui-fabric-react/lib/DetailsList';

// local interfaces

interface ILessonPropsPanelProps {
    isOpen: boolean;
    lesson?: ILessonDataProps;
    onPanelClosed(props?:IListPanelClosed): void;
}

interface ILessonPropsPanelState {
    loaded: boolean;
    isOpen: boolean;
    teachers: ILessonTeachersDataProps[];
}

export default class LessonPropsPanel extends React.Component<ILessonPropsPanelProps, ILessonPropsPanelState> {
    private lessonService:LessonService = new LessonService();
    private listColumns:IColumn[] = [
        {
            key: "userName",
            fieldName: "name",
            name: "Nama Lengkap",
            minWidth: 0
        },
        {
            key: "email",
            fieldName: "email",
            name: "Alamat Email",
            minWidth: 200
        },
        {
            key: "phoneNumber",
            fieldName: "phoneNumber",
            name: "No. Telepon",
            minWidth: 100
        },
        {
            key: "level",
            fieldName: "level",
            name: "Level",
            minWidth: 100
        }
    ];

    constructor(props: ILessonPropsPanelProps) {
        super(props);

        this.state = {
            isOpen: false,
            loaded: false,
            teachers: []
        }
    }

    private refresh = async (props: ILessonPropsPanelProps) => {
        if (props.lesson) {
            this.setState({
                isOpen: props.isOpen,
                loaded: false
            });
            const teachers = await this.lessonService.getTeachersByLessonId(props.lesson.id);
            this.setState({
                loaded: true,
                teachers
            });
        } else {
            this.setState({isOpen: false});
        }
    }
    
    public async componentWillMount() {
        this.refresh(this.props);
    }

    public componentWillReceiveProps(props: ILessonPropsPanelProps) {
        this.setState({isOpen: props.isOpen});
        if (props.isOpen) {
            this.refresh(props);
        }
    }

    private _onPanelClosed = (props?:IListPanelClosed) => {
        this.props.onPanelClosed(props);
    }

    public render() {
        return (
            <Panel
                headerText={"Daftar Guru/Pengajar"}
                isOpen={this.state.isOpen}
                type={PanelType.large}
                onDismiss={() => this._onPanelClosed()}
                closeButtonAriaLabel="Tutup"
                isFooterAtBottom={true}>
                {
                    !this.state.loaded ? (
                        <Spinner size={SpinnerSize.large} label="Mengambil data ..." labelPosition="right"/>
                    ) : null
                }
                {
                    this.state.loaded && this.state.teachers.length > 0 ? (
                        <DetailsList columns={this.listColumns} items={this.state.teachers} />
                    ) : null
                }
                {
                    this.state.loaded && this.state.teachers.length < 1 ? (
                        <Text>Guru/pengajar untuk pelajaran ini tidak dapat ditemukan</Text>
                    ) : null
                }
            </Panel>
        );
    }
}
