import React from 'react';

// import styles
import kepInternalStyles from './../../styles/kepinternal.module.scss';

// import interfaces
import { ILessonDataProps, ILessonDataType } from './../../props/data';
import { IListPanelClosed, IFilterOptionProps, IFilterProps } from './../../props/general';

// import services
import ModuleService from './../../services/module';
import LessonService from './../../services/lesson';
import GeneralService from './../../services/general';

// import fabric ui
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Panel } from 'office-ui-fabric-react/lib/Panel';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Text } from 'office-ui-fabric-react/lib/Text';

// local interfaces

interface IFilterDataPanelProps {
    isOpen: boolean;
    data: any;
    fieldName: string;
    panelHeader: string;
    currentFilters: IFilterProps[];
    onPanelClosed(props?:IListPanelClosed): void;
    onFilterApplied(fieldName:string, selectedFilters:IFilterOptionProps[]): void;
}

interface IFilterDataPanelState {
    loading: boolean;
    isOpen: boolean;
    panelHeader: string;
    options: IFilterOptionProps[];
}

export default class LessonListFilterPanel extends React.Component<IFilterDataPanelProps, IFilterDataPanelState> {
    private lessonService:LessonService = new LessonService();

    constructor(props: IFilterDataPanelProps) {
        super(props);

        this.state = {
            loading: true,
            isOpen: false,
            panelHeader: "",
            options: []
        }
    }

    private init = (props:IFilterDataPanelProps) => {
        let {data, fieldName, panelHeader, isOpen, currentFilters} = props;

        this.setState({loading: true});
        let uniqueValues = GeneralService.getUniqueFieldValues(data, fieldName);
        let relatedFilter = currentFilters.find((filter) => {return filter.fieldName === fieldName;});
        let options = uniqueValues.map((value:any) => {
            let checked = false;
            if (relatedFilter && relatedFilter.selectedOptions) {
                let selectedKeys = relatedFilter.selectedOptions.map((opt) => {return opt.key.toLowerCase();});
                if (selectedKeys.indexOf(value.toLowerCase()) > -1) {
                    checked = true;
                }
            }
            return {key: value, text: value, checked};
        });

        this.setState({
            loading: false,
            isOpen,
            panelHeader,
            options
        });
    }

    public async componentWillReceiveProps(props:IFilterDataPanelProps) {
        this.init(props);
    }
    
    public async componentWillMount() {
        this.init(this.props);
    }

    private _onPanelClosed = (props?:IListPanelClosed) => {
        this.props.onPanelClosed(props);
    }

    private _applyFilter = () => {
        /*this.props.onPanelClosed({
            fieldName: this.state.fieldName,
            text: this.state.filterText,
            selectedOptions: this.state.options.filter((opt) => {return opt.checked;})
        }, {refreshData: true})*/
        this.props.onFilterApplied(this.props.fieldName, this.state.options.filter((opt) => {return opt.checked;}));
    }

    private renderPanelFooter = ():JSX.Element => {
        return (
            <Stack horizontal horizontalAlign="baseline" tokens={{childrenGap: 10}}>
                <Stack.Item>
                    <PrimaryButton label={"Filter"} onClick={() => this._applyFilter()}>Filter</PrimaryButton>
                </Stack.Item>
                <Stack.Item>
                    <DefaultButton label={"Batalkan"} onClick={() => this._onPanelClosed()}>Batal</DefaultButton>
                </Stack.Item>
            </Stack>
        );
    }

    private _onCheckboxClicked = (key:string) => {
        let {options} = this.state;
        options = options.map((option) => {
            if (option.key === key) {option.checked = option.checked ? !option.checked : true;}
            return option;
        });

        this.setState({options});
    }

    private _onSearchChanged = (evt?: any, key?: string) => {
        let {options} = this.state;
        options = options.map((option) => {
            if (option.key.toLowerCase().indexOf(key || "") > -1 || option.text.toLowerCase().indexOf(key || "") > -1) {
                option.hide = false;
            } else {option.hide = true;}
            return option;
        });
        this.setState({options});
    }

    public render() {
        let {panelHeader, options, isOpen, loading} = this.state;
        return (
            <Panel
                headerText={panelHeader}
                isOpen={isOpen}
                onDismiss={() => this._onPanelClosed()}
                closeButtonAriaLabel="Tutup"
                onRenderFooterContent={!this.state.loading ? this.renderPanelFooter : undefined}
                isFooterAtBottom={true}>
                { loading ? <Spinner size={SpinnerSize.large} label="Harap tunggu ... "/> : null }
                {
                    !loading ? (
                        <Stack tokens={{childrenGap: 10}}>
                            <Stack.Item>
                                <TextField placeholder="Cari dengan kata kunci ... " onChange={this._onSearchChanged}/>
                            </Stack.Item>
                            <Stack tokens={{childrenGap: 10}}>
                                {
                                    options.filter((option) => {return !option.hide}).map((option) => {
                                        return <Checkbox label={option.text} key={option.key} checked={option.checked} onChange={() => this._onCheckboxClicked(option.key)} />;
                                    })
                                }
                                {
                                    options.filter((option) => {return !option.hide}).length < 1 ? <Text className={kepInternalStyles.errorText}>Hasil pencarian tidak ditemukan</Text> : null
                                }
                            </Stack>
                        </Stack>
                    ) : null
                }
            </Panel>
        );
    }
}
