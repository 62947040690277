import moment from 'moment';

// import services
import GeneralService from './../services/general';
import LessonService from './../services/lesson';
import ScheduleService from './../services/schedule';

// import interfaces
import {
    IEventDataProps, 
    IClassDataProps, 
    IModuleDataProps, 
    ILessonDataProps, 
    IUserDataProps, 
    ISchedulePreviewProps, 
    ISchedulePreviewLessonProps, 
    IScheduleDataProps, 
    IKEPDataProps, 
    IIntakeDataProps, 
    ILessonTeachersDataProps
} from './../props/data';
import schedules from '../sample/schedule';

interface IRandomiseSelectedProps {
    KEP: IKEPDataProps;
    intake: IIntakeDataProps;
    startDate: string;
}

interface IRandomiseDataProps {
    teachers: IUserDataProps[];
    lessons: ILessonDataProps[];
    events: IEventDataProps[];
    classes: IClassDataProps[];
    modules: IModuleDataProps[];
}

interface IClassGrouping {
    id: string;
    classData: IClassDataProps;
    modules: IModuleGrouping[]
}

interface IModuleGrouping {
    id: string;
    moduleData: IModuleDataProps;
    lessons: ILessonDataProps[];
}

interface ITeacherSchedule extends IUserDataProps {
    schedules: IScheduleDataProps[];
}

export default class Randomise {
    private selected:IRandomiseSelectedProps | undefined;
    private data:IRandomiseDataProps = {teachers: [], lessons: [], events: [], classes: [], modules: []};
    private teachersSchedules:ITeacherSchedule[] = [];
    private scheduleService:ScheduleService = new ScheduleService();

    private lessonService:LessonService = new LessonService();

    // teachers list on what class and what kep are they teaching
    private teacherTeachingLocation:{
        teacherId: string;
        KEPId: string;
        classId: string;
        date: string;
    }[] = [];

    constructor(selected?:IRandomiseSelectedProps, data?:IRandomiseDataProps) {
        this.selected = selected;
        this.data = data ? data : this.data;
        this.teachersSchedules = this.data.teachers.map((teacher) => {
            return {...teacher, schedules: []}
        });

        // reorder classes
        this.data.classes = this.data.classes.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
    }

    private arrangeLessons() {
        if (this.selected) {
            let classesGrouping: IClassGrouping[] = this.data.classes.map((cl) => {
                // get all modules for the selected KEP and related to class
                let relatedModules:IModuleDataProps[] = this.data.modules.filter((module) => {return module.classId === cl.id && this.selected && module.includedKEPId.indexOf(this.selected.KEP.id) > -1;});
                relatedModules = relatedModules.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));

                let modulesGrouping:IModuleGrouping[] = relatedModules.map((module) => {
                    let relatedLessons = this.data.lessons.filter((lesson) => {return lesson.moduleId === module.id;});
                    relatedLessons = relatedLessons.sort((a,b) => (a.chapter > b.chapter) ? 1 : ((b.chapter > a.chapter) ? -1 : 0));
                    return {
                        id: module.id,
                        moduleData: module,
                        lessons: relatedLessons
                    }
                });

                return {
                    id: cl.id,
                    classData: cl,
                    modules: modulesGrouping
                }
            });

            let arrangedLessons:ILessonDataProps[][] = [];
            classesGrouping.forEach((cl) => {
                let lessons:ILessonDataProps[] = [];
                cl.modules.forEach((module) => {
                    module.lessons.forEach((lesson) => {
                        for (var ctr=0; ctr < lesson.duration; ctr++) {
                            lessons.push(lesson);
                        }
                    });
                });

                arrangedLessons.push(lessons);
            });

            return arrangedLessons;
        } else {return [];}
    }

    public generateSchedules() {
        if (this.selected) {
            let schedules:ISchedulePreviewProps[] = [];
            let totalWeeks = moment(this.selected.intake.endDate).diff(moment(this.selected.startDate), 'week');

            // inject schedules with empty data
            for (var counter=0; counter<totalWeeks; counter++) {
                schedules.push({date: moment(this.selected.startDate).add(counter, "week").toISOString()});
            }

            let arrangedLessons = this.arrangeLessons();
            
            this.data.events.forEach((event) => {
                let relatedSchedules = schedules.filter((schedule) => {return schedule.date >= event.startDate && schedule.date <= event.endDate;});
                relatedSchedules.forEach((schedule) => {
                    schedule.event = event;
                });
            });

            let idx = 0;
            schedules.forEach((schedule) => {
                if (!schedule.event) {
                    schedule.lessons = [];
                    arrangedLessons.forEach((lessons) => {
                        if (lessons[idx] && schedule.lessons) {
                            schedule.lessons.push({...lessons[idx], rerandom: true})
                        }
                    });
                    idx++;
                }
            });

            // clear all empty trailing schedules
            idx = schedules.length - 1;
            do {
                let currentLessons = schedules[idx].lessons || [];
                if (currentLessons.length < 1) {
                    schedules.splice(idx, 1);
                    idx--;
                } else {idx = -1;}
            } while (idx > -1)

            return schedules;
        } else {return [];}
    }
    
    private async generateTeachersSchedules(startDate:string, endDate:string) {
        try {
            let teachersSchedules:ITeacherSchedule[] = this.data.teachers.map((teacher) => {
                return {...teacher, schedules: []}
            });

            if (this.selected) {
                let fullSchedules:IScheduleDataProps[] = await this.scheduleService.getRange(startDate, endDate);
                fullSchedules.forEach((schedule) => {
                    let teacher = teachersSchedules.find((teacher) => {return teacher.id === schedule.teacherId;});
                    if (teacher) {teacher.schedules.push(schedule);}
                });
            }

            this.teachersSchedules = teachersSchedules;
        } catch(e) {throw(e);}
    }
    
    private groupTeacherByLevel(teachers:ILessonTeachersDataProps[]):{level: number; teachers: ILessonTeachersDataProps[]}[] {
        let results:{level: number; teachers: ILessonTeachersDataProps[]}[] = [];
        teachers.forEach((teacher) => {
            let index = results.findIndex((res) => {return res.level === teacher.level;});
            if (index > -1) {
                results[index].teachers.push(teacher);
            } else {
                results.push({level: teacher.level, teachers: [teacher]});
            }
        });

        return results;
    }

    private filterTeacherBySchedule(teachers:ILessonTeachersDataProps[], dates:string[]):ILessonTeachersDataProps[] {
        teachers = teachers.filter((teacher) => {
            let results = this.teachersSchedules.find((teacherSchedule) => {
                return (teacher.id + "").toLowerCase() === (teacherSchedule.id + "").toLowerCase();
            });

            let teacherSchedules:IScheduleDataProps[] = results && results.schedules ? results.schedules : [];
            if (teacherSchedules.length === 0 && teacher.maxLessonsPerWeek && teacher.maxLessonsPerWeek > 0) {
                return true;
            } else if (teacherSchedules) {
                let capable = true;
                for (var ctr=0; ctr<dates.length; ctr++) {
                    const date = dates[ctr];
                    const startWeek:string = GeneralService.getStartOfWeek(date);
                    const endWeek:string = moment(startWeek).add(1, 'weeks').toISOString();

                    // check if there is any schedule with same date
                    if (teacherSchedules.find((schedule) => {return moment(schedule.date).format("DD/MM/YYYY") === moment(date).format("DD/MM/YYYY");})) {
                        capable = false;
                        break;
                    } else {
                        let totalSchedulesInWeek:number = teacherSchedules.filter((schedule) => {
                            return schedule.date >= startWeek && schedule.date <= endWeek;
                        }).length;
                        
                        if (teacher.maxLessonsPerWeek && totalSchedulesInWeek >= teacher.maxLessonsPerWeek) {
                            capable = false;
                            break;
                        }
                    }
                };

                return capable;
            } else {return false;}
        });
        return teachers;
    }

    private filterTeacherBySameClassSchedule(KEPId:string, classId: string, teachers:ILessonTeachersDataProps[]):ILessonTeachersDataProps[] {
        teachers = teachers.filter((teacher) => {
            let sameClassSchedule = this.teacherTeachingLocation.filter((ttl) => {
                return ttl.KEPId.toLowerCase() === KEPId.toLowerCase() && 
                    ttl.classId.toLowerCase() === classId.toLowerCase() && 
                    ttl.teacherId === teacher.id;
            });
            return sameClassSchedule.length > 0 ? false : true;
        });
        return teachers;
    }

    private filterTeacherByTeachingLocationDate(dates:string[], teachers:ILessonTeachersDataProps[]):ILessonTeachersDataProps[] {
        dates = dates.map((date) => {return date.toLowerCase()});
        teachers = teachers.filter((teacher) => {
            const sameDate = this.teacherTeachingLocation.filter((sc) => {
                return teacher.id === sc.teacherId && dates.indexOf(moment(sc.date).toISOString().toLowerCase()) > -1;
            });
            return sameDate.length > 0 ? false : true;
        });
        return teachers;
    }

    private async getTeacherForLesson(level:number, lessonId:string, KEP:IKEPDataProps, classId:string, dates:string[]):Promise<IUserDataProps | undefined> {
        // get teachers related to the lessons - try for 5 times maximum
        let tryCounter = 0;
        let relatedTeachers:ILessonTeachersDataProps[] | undefined = undefined;
        do {
            try {
                tryCounter++
                relatedTeachers = await this.lessonService.getTeachersByLessonId(lessonId);
            } catch(e) {
                relatedTeachers = undefined;
                await new Promise((resolve) => {setTimeout(() => {return resolve;}, 5000)});
            }
        } while(tryCounter < 5 && relatedTeachers == undefined);

        if (relatedTeachers && relatedTeachers.length > 0) {
            // filter teacher by kep id and level
            relatedTeachers = relatedTeachers.filter((teacher) => {
                return teacher.includedKEPId && teacher.includedKEPId.indexOf(KEP.id) > -1 ? true : false; 
            }).filter((teacher) => {
                return teacher.level === level;
            });
            // filter teacher by schedules
            relatedTeachers = this.filterTeacherBySchedule(relatedTeachers, dates);
            relatedTeachers = this.filterTeacherBySameClassSchedule(KEP.id, classId, relatedTeachers);
            relatedTeachers = this.filterTeacherByTeachingLocationDate(dates, relatedTeachers);

            // check if there are teahcer in the same city
            let teacherInSameCity = relatedTeachers.filter((teacher) => {
                return teacher.city && KEP.city ? teacher.city.toLowerCase() === KEP.city.toLowerCase() : false;
            });
            if (teacherInSameCity.length > 0) {
                relatedTeachers = teacherInSameCity;
            }

            let randomNumber = (Math.floor(Math.random() * Math.floor(relatedTeachers.length)));
            return relatedTeachers[randomNumber];
        } else if (relatedTeachers && relatedTeachers.length < 1) {
            throw({status: 110, message: "Tidak ada guru yang di assign untuk dapat mengajar pelajaran ini."});
        } else {
            throw({status: 400, message: "Error saat mengacak guru. Coba beberapa saat lagi."});
        }
    }

    public async randomise(schedules:ISchedulePreviewProps[], updateLoadingText:(text:string) => void) {
        const totalLevel = 2;
        this.teacherTeachingLocation = [];
        if (this.selected) {
            try {
                let intakeSchedules = await this.scheduleService.getIntakeByKEPId(this.selected.KEP.id, this.selected.intake.id);
                intakeSchedules.forEach((schedule) => {
                    if (schedule.KEPId && schedule.lesson && schedule.lesson.module && schedule.lesson.module.classId && schedule.teacherId) {
                        this.teacherTeachingLocation.push({
                            KEPId: schedule.KEPId,
                            classId: schedule.lesson.module.classId,
                            teacherId: schedule.teacherId,
                            date: schedule.date
                        });
                    }
                });

                let expectedLevel = (Math.floor(Math.random() * Math.floor(totalLevel))) + 1;
                await this.generateTeachersSchedules(schedules[0].date, schedules[schedules.length - 1].date);
                for (var scheduleIndex=0; scheduleIndex<schedules.length; scheduleIndex++) {
                    let schedule = schedules[scheduleIndex];
                    if (schedule.lessons) {
                        for (var lessonIndex=0; lessonIndex<schedule.lessons.length; lessonIndex++) {
                            let lesson = schedule.lessons[lessonIndex];
                            if (lesson.rerandom) {
                                lesson.touched = true;
                                updateLoadingText(`Mencari guru untuk pelajaran ${lesson.name}`);
                                
                                // get all lesson dates
                                let dates:string[] = [schedule.date];
                                let relatedLessons = schedules.map((sch) => {
                                    let relatedLesson = sch.lessons ? sch.lessons.find((cl) => {return cl.moduleId === lesson.moduleId && cl.chapter === lesson.chapter;}) : undefined;
                                    if (relatedLesson) {dates.push(sch.date);}
                                    return relatedLesson;
                                });
                                relatedLessons = relatedLessons.filter(Boolean);

                                // randomise teacher
                                try {
                                    lesson.errorMessage = undefined;
                                    let level = expectedLevel;
                                    let teacher:IUserDataProps | undefined = undefined;
                                    let addition:number = (expectedLevel === totalLevel) ? -1 : 1;
                                    do {
                                        try {
                                            teacher = await this.getTeacherForLesson(level, lesson.id, this.selected.KEP, lesson.module ? lesson.module.classId : "", dates);
                                        } catch(e) {
                                            if (e.status === 110) {
                                                throw("Tidak ada guru yang di assign untuk dapat mengajar pelajaran ini.");
                                            } else {throw(e.message);}
                                        }

                                        // if related teacher found
                                        if (teacher) {
                                            lesson.teacher = teacher;
                                            // update all related lessons
                                            (relatedLessons as ISchedulePreviewLessonProps[]).forEach((relatedLesson) => {
                                                relatedLesson.teacher = teacher;
                                                relatedLesson.rerandom = false;
                                                relatedLesson.touched = true;
                                            });
                                            expectedLevel = level === 1 ? 2 : 1;
                                            this.teacherTeachingLocation.push({
                                                teacherId: teacher.id,
                                                classId: lesson.module ? lesson.module.classId : "",
                                                KEPId: this.selected.KEP.id,
                                                date: schedule.date
                                            });
                                        } else {
                                            level = level + addition;
                                        }
                                    } while(teacher === undefined && level <= totalLevel && level >= 1);

                                    if (!teacher) {
                                        throw("Tidak bisa menemukan guru yang cocok.");
                                    }
                                } catch(e) {
                                    lesson.errorMessage = e;
                                }
                            }
                        }
                    }
                }

                return schedules;
            } catch(e) {throw(e);}
        } else {throw({message: "Data yang dibutuhkan belum terisi semua."})}
    }

    // getter and seeter
    public setSelected(selected:IRandomiseSelectedProps) {
        this.selected = selected;
    }

    public getSelected():IRandomiseSelectedProps | undefined {
        return this.selected;
    }

    public setData(data:IRandomiseDataProps) {
        this.data = data;
    }

    public getData():IRandomiseDataProps {
        return this.data;
    }
}