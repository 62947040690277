import React from 'react';
import _ from 'underscore';
import Cities from './../../props/city';

// import styles
import kepInternalStyles from './../../styles/kepinternal.module.scss';

// import interfaces
import { IUserDataProps, TUserRoleType, ILessonDataProps, IClassDataProps, IKEPDataProps } from './../../props/data';
import { IListPanelClosed } from './../../props/general';

// import components
import Form from './../../components/forms/index';
import {IFormFieldProps, IFieldChoiceProps} from './../../components/forms/formProps';

// import services
import UserService from './../../services/user';
import ClassService from './../../services/class';
import LessonService from './../../services/lesson';
import KEPService from './../../services/kep';
import GeneralService from './../../services/general';
import FormMetadataService from './../../components/forms/services/metadata';
import FormValidationService from './../../components/forms/services/validation';
import TeacherLessonConnectionService from './../../services/teacherLessonConnection';

// import fabric ui
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { Dropdown, IDropdownOption, DropdownMenuItemType } from 'office-ui-fabric-react/lib/Dropdown';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { DetailsList, SelectionMode, IDragDropEvents, IDragDropContext } from 'office-ui-fabric-react/lib/DetailsList';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { ISelectableOption } from '@fluentui/react';

// local interfaces

interface ITeacherPropsPanelProps {
    data?: IUserDataProps;
    onPanelClosed(props?:IListPanelClosed): void;
}

interface ITeacherPropsPanelState {
    loaded: boolean;
    saving: boolean;
    classes: IClassDataProps[];
    lessons: ILessonDataProps[];
    data?: IUserDataProps;
    isUpdate: boolean;
    formFields: IFormFieldProps[];
}

export default class TeacherPropsPanel extends React.Component<ITeacherPropsPanelProps, ITeacherPropsPanelState> {
    private _includedLessonsDraggedItem: any;
    private userService:UserService = new UserService();
    private kepService:KEPService = new KEPService();
    private classService:ClassService = new ClassService();
    private lessonService:LessonService = new LessonService();
    private teacherLessonConnectionService:TeacherLessonConnectionService = new TeacherLessonConnectionService();

    private defaultFormFields:IFormFieldProps[] = [
        {
            promoted: true,
            key: "id",
            title: "ID",
            type: "textfield",
            isRequired: false,
            isDisabled: true,
            isHidden: true
        },
        {
            promoted: true,
            key: "name",
            title: "Nama pengguna",
            type: "textfield",
            isRequired: true,
            isDisabled: false
        },
        {
            promoted: true,
            key: "email",
            title: "Email",
            type: "textfield",
            textfieldConfiguration: {
                validationType: "email"
            },
            isRequired: true,
            isDisabled: false
        },
        {
            promoted: true,
            key: "password",
            title: "Password",
            type: "password",
            isRequired: true,
            isDisabled: false
        },
        {
            promoted: true,
            key: "repeatPassword",
            title: "Ulangi password",
            type: "repeatPassword",
            isRequired: true,
            isDisabled: false
        },
        {
            promoted: true,
            key: "phoneNumber",
            title: "No. Telepon",
            type: "textfield",
            textfieldConfiguration: {
                validationType: "phone"
            },
            isRequired: false,
            isDisabled: false
        },
        {
            promoted: true,
            key: "address",
            title: "Alamat",
            type: "textarea",
            isRequired: false,
            isDisabled: false
        },
        {
            promoted: true,
            key: "city",
            title: "Domisili/Kota",
            type: "dropdown",
            isRequired: false,
            isDisabled: false,
            choicesConfiguration: {
                choices: Cities
            },
            value: "Surabaya"
        },
        {
            promoted: true,
            key: "roles",
            title: "Wenenang",
            type: "multiselectdropdown",
            choicesConfiguration: {
                choices: [
                    {key: "Administrator", text: "Administrator"},
                    {key: "Sekretariat", text: "Sekretariat"},
                    {key: "Guru", text: "Guru"}
                ].filter((role) => {
                    if (GeneralService.isCurrentUserAdmin()) {
                        return true;
                    } else if (GeneralService.isCurrentUserSecretary() && role.key === 'Administrator') {
                        return false;
                    } else if (GeneralService.isCurrentUserSecretary()) {
                        return true;
                    } else if (GeneralService.isCurrentUserTeacher()) {
                        return false;
                    }
                })
            },
            isRequired: true,
            isDisabled: false
        },
        {
            promoted: true,
            key: "maxLessonsPerWeek",
            title: "Maksimal mengajar dalam 1 minggu",
            type: "number",
            isRequired: true,
            isHidden: true,
            isDisabled: false
        },
        {
            promoted: true,
            key: "includedKEPId",
            title: "Dapat mengajar di KEP",
            type: "multiselectdropdown",
            choicesConfiguration: {
                choices: []
            },
            isRequired: true,
            isHidden: true,
            isDisabled: false
        },
        {
            promoted: false,
            key: "selectedLessons",
            title: "Pelajaran terkait",
            type: "multiselectdropdown",
            choicesConfiguration: {
                choices: [],
                styles: {dropdownItem: {marginBottom: 10}, dropdownItemSelected: {marginBottom: 10}},
                onRenderOption: (option:ISelectableOption) => {
                    let lesson = this.state.lessons.find((lesson) => {return lesson.id === option.key;});
                    if (lesson) {
                        return (
                            <Stack styles={{root: {margin: 5}}}>
                                <Stack.Item>
                                    <Text style={{fontWeight: 600}}>{option.text}</Text>
                                </Stack.Item>
                                <Stack.Item>
                                    <Text variant="small" style={{fontWeight: 400, color: "#848484"}}>Bab {lesson.chapter} - {lesson.module ? lesson.module.name : ""}</Text>
                                </Stack.Item>
                            </Stack>
                        );
                    } else {return null;}
                }
            },
            isRequired: true,
            isHidden: true,
            isDisabled: false
        },
        {
            promoted: true,
            key: "includedLessons",
            title: "Urutkan pelajaran dari yang paling diprioritaskan",
            type: "custom",
            onRender: (field:IFormFieldProps) => {
                return (
                    <DetailsList
                        items={(field.value || []).filter((item:any) => {return !item.deleted;})}
                        selectionMode={SelectionMode.none}
                        columns={[
                            {
                                key: "text", 
                                fieldName: "text", 
                                name: "Nama", 
                                minWidth: 0
                            },
                            {
                                key: "class", 
                                name: "Kelas", 
                                minWidth: 0,
                                onRender: (item) => {
                                    let lesson = this.state.lessons.find((lesson) => {return item.id === lesson.id;});
                                    return <Text>{lesson && lesson.module && lesson.module.class ? lesson.module.class.name : ""}</Text>;
                                }
                            },
                            {
                                key: "module", 
                                name: "Modul", 
                                minWidth: 0,
                                onRender: (item) => {
                                    let lesson = this.state.lessons.find((lesson) => {return item.id === lesson.id;});
                                    return <Text>{lesson && lesson.module ? lesson.module.name : ""}</Text>;
                                }
                            },
                            {
                                key: "chapter", 
                                name: "Bab", 
                                minWidth: 0,
                                onRender: (item) => {
                                    let lesson = this.state.lessons.find((lesson) => {return item.id === lesson.id;});
                                    return <Text>{lesson ? lesson.chapter + "" : ""}</Text>;
                                }
                            },
                            {
                                key: "level", 
                                fieldName: "level", 
                                name: "Level", 
                                minWidth: 50,
                                maxWidth: 50,
                                onRender: (item, idx) => {
                                    return (
                                        <Dropdown options={[
                                                {key: 1, text: "1"},
                                                {key: 2, text: "2"}
                                            ]} 
                                            selectedKey={item.level || 1} 
                                            onChange={(evt, option) => {this._onIncludedLessonsLevelChanged(item.id, option)}} />
                                    ); 
                                }
                            }
                        ]}
                        isHeaderVisible={true}/>
                );
            },
            isRequired: false,
            isHidden: true,
            isDisabled: false
        }
    ];

    private getLessonOptions = (lessons:ILessonDataProps[], classes:IClassDataProps[]) => {
        let options:IFieldChoiceProps[] = [];
        classes = classes.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
        classes.forEach((cl) => {
            let opts = lessons.filter((lesson) => {return lesson.module ? lesson.module.classId === cl.id : false;});
            if (opts.length > 0) {
                options.push({key: cl.id, text: cl.name, itemType: DropdownMenuItemType.Header});
                opts = opts.sort((a, b) => {

                    var o1 = a.module ? a.module.order : -1;
                    var o2 = b.module ? b.module.order : -1;
                  
                    var p1 = a.chapter;
                    var p2 = b.chapter;
                  
                    if (o1 < o2) return -1;
                    if (o1 > o2) return 1;
                    if (p1 < p2) return -1;
                    if (p1 > p2) return 1;
                    return 0;
                });
                opts.forEach((opt) => {options.push({key: opt.id, text: opt.name});});
            }
        });

        return options;
    }

    private getKEPOptions(allKep: IKEPDataProps[]) {
        let options:IFieldChoiceProps[] = [];
        let groupedKep = GeneralService.groupBy(allKep, (kep:IKEPDataProps) => kep.city);
        Array.from(groupedKep).map((gk) => {
            options.push({key: gk[0], text: gk[0], itemType: DropdownMenuItemType.Header});
            gk[1].forEach((kep:IKEPDataProps) => {
                options.push({key: kep.id, text: kep.name});
            });
        });

        return options;
    }

    private _onIncludedLessonsLevelChanged = (lessonId?: string, selectedOption?: IDropdownOption) => {
        let {formFields} = this.state;
        let includedLessonsField = formFields.find((field) => {return field.key === 'includedLessons';});
        if (includedLessonsField && lessonId) {
            includedLessonsField.value.forEach((value:any) => {
                if (value.id === lessonId) {
                    value.level = selectedOption ? selectedOption.key : "1";
                    value.touched = true;
                }
            })
            this.setState({formFields});
        }
    }

    constructor(props: ITeacherPropsPanelProps) {
        super(props);

        this.state = {
            loaded: false,
            saving: false,
            isUpdate: props.data ? true : false,
            data: props.data,
            lessons: [],
            classes: [],
            formFields: this.defaultFormFields
        }
    }

    public async componentWillMount() {
        let data:any = this.props.data;
        let [lessons, classes, kep] = await Promise.all([await this.lessonService.getAll(), await this.classService.getAll(), await this.kepService.getAll()]);
        let relatedLessons:any = [];
        if (data && data.id) {
            let relatedLessonsData = await this.teacherLessonConnectionService.getByTeacherId(data.id);
            relatedLessonsData = relatedLessonsData.sort((a,b) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0));
            relatedLessonsData.forEach((d) => {
                let relatedLesson = lessons.find((lesson) => {return lesson.id === d.lessonId;});
                if (relatedLesson) {
                    relatedLessons.push({
                        id: relatedLesson.id,
                        text: relatedLesson.name,
                        level: d.level,
                        connectionId: d.id
                    });
                }
            });
            data.includedLessons = relatedLessons;
            data.selectedLessons = relatedLessons.map((lesson:any) => {return lesson.id;});
        }

        let formFields:IFormFieldProps[] = _.clone(this.defaultFormFields);
        formFields.forEach((field) => {
            if (field.key === 'selectedLessons' && field.choicesConfiguration) {
                field.choicesConfiguration.choices = this.getLessonOptions(lessons, classes);
            } else if (field.key === 'includedKEPId' && field.choicesConfiguration) {
                field.choicesConfiguration.choices = this.getKEPOptions(kep);
            }

            if (this.props.data) {
                if (field.key === 'password' || 
                    field.key === 'repeatPassword') {
                    field.isHidden = true;
                }
            }

            if (this.props.data && this.props.data.roles) {
                if (this.props.data.roles.indexOf("Guru") > -1) {
                    if (field.key === 'selectedLessons' || 
                        field.key === 'level' || 
                        field.key === 'includedKEPId' || 
                        field.key === 'daysAvailability' || 
                        field.key === 'maxLessonsPerWeek' || 
                        field.key === 'includedLessons') {
                        field.isHidden = false;
                    }
                } else {
                    if (field.key === 'selectedLessons' || 
                        field.key === 'level' || 
                        field.key === 'includedKEPId' || 
                        field.key === 'daysAvailability' || 
                        field.key === 'maxLessonsPerWeek' || 
                        field.key === 'includedLessons') {
                        field.isHidden = true;
                    }
                }
            }
        });

        this.setState({
            lessons,
            classes,
            loaded: true,
            isUpdate: data ? true : false,
            data,
            formFields
        });
    }

    private _onPanelClosed = (props?:IListPanelClosed) => {
        this.props.onPanelClosed(props);
    }

    private _onCreate = async ():Promise<void> => {
        let fieldsValidation = FormValidationService.fields(this.state.formFields);
        if (!fieldsValidation.isError) {
            this.setState({
                saving: true, 
                formFields: this.state.formFields.map((field) => {
                    field.isDisabled = true;
                    return field;
                })
            });

            let metadata = FormMetadataService.generate(this.state.formFields);
            let errorData = await this.userService.create(metadata);
            let messageBarText = `Pengguna ${metadata.name} berhasil ditambahkan`;
            if (errorData.length > 0) {

            }
            this.setState({saving: false});
            this._onPanelClosed({
                refreshData: true, 
                messageBar: {
                    text: messageBarText,
                    type: MessageBarType.success
                }
            });
        } else {
            this.setState({
                saving: false,
                formFields: fieldsValidation.fields.map((field) => {
                    field.isDisabled = false;
                    return field;
                })
            });
        }
    }

    private _onUpdate = async ():Promise<void> => {
        let fieldsValidation = FormValidationService.fields(this.state.formFields);
        if (!fieldsValidation.isError) {
            this.setState({saving: true});this.setState({
                saving: true, 
                formFields: this.state.formFields.map((field) => {
                    field.isDisabled = true;
                    return field;
                })
            });

            let metadata = FormMetadataService.generate(this.state.formFields);
            let updatedData = await this.userService.update(metadata.id, metadata);
            this.setState({saving: false});
            this._onPanelClosed({
                refreshData: true, 
                messageBar: {
                    text: `Pengguna ${updatedData.name} berhasil diubah`,
                    type: MessageBarType.success
                }
            });
        }
    }

    private renderPanelFooter = ():JSX.Element => {
        return (
            <Stack horizontalAlign="baseline">
                {
                    this.state.saving ? (
                        <Spinner label={this.state.isUpdate ? "Mengubah data pengguna ... " : "Menambahkan Pengguna baru ... "} labelPosition="right" size={SpinnerSize.medium}/>
                    ) : null
                }
                {
                    !this.state.saving && this.state.loaded ? (
                        <Stack horizontal tokens={{childrenGap: 10}}>
                            <Stack.Item>
                            { this.state.isUpdate ? <PrimaryButton label={"Ubah"} onClick={this._onUpdate}>Ubah</PrimaryButton> : null }
                            { !this.state.isUpdate ? <PrimaryButton label={"Tambahkan"} onClick={this._onCreate}>Tambahkan</PrimaryButton> : null }
                            </Stack.Item>
                            <Stack.Item>
                                <DefaultButton label={"Tambahkan"} onClick={() => this._onPanelClosed()}>Batal</DefaultButton>
                            </Stack.Item>
                        </Stack>
                    ) : null
                }
            </Stack>
        );
    }

    private showHideFieldsBasedOnRoles(fields:IFormFieldProps[]):IFormFieldProps[] {
        let rolesField = fields.find((field) => {return field.key === 'roles';});
        let roles:TUserRoleType[] = [];
        if (rolesField && rolesField.value) {
            roles = rolesField.value;
        }

        fields.forEach((field) => {
            if (roles.indexOf("Guru") > -1) {
                if (field.key === 'selectedLessons' || 
                    field.key === 'level' || 
                    field.key === 'includedKEPId' || 
                    field.key === 'daysAvailability' || 
                    field.key === 'maxLessonsPerWeek' || 
                    field.key === 'includedLessons') {
                    field.isHidden = false;
                }
            } else {
                if (field.key === 'selectedLessons' || 
                    field.key === 'level' || 
                    field.key === 'includedKEPId' || 
                    field.key === 'daysAvailability' || 
                    field.key === 'maxLessonsPerWeek' || 
                    field.key === 'includedLessons') {
                    field.isHidden = true;
                }
            }
        });

        return fields;
    }

    private setIncludedLessonsFieldValue = (fields:IFormFieldProps[]):IFormFieldProps[] => {
        let selectedLessonsField = fields.find((field) => {return field.key === 'selectedLessons';});
        let includedLessonsField = fields.find((field) => {return field.key === 'includedLessons';});
        if (selectedLessonsField && includedLessonsField) {
            let selectedOptions = (selectedLessonsField.value || []).map((lessonId:any) => {
                if (selectedLessonsField && selectedLessonsField.choicesConfiguration && selectedLessonsField.choicesConfiguration.choices) {
                    let relatedLesson = selectedLessonsField.choicesConfiguration.choices.find((choice) => {return choice.key === lessonId;});
                    return relatedLesson;
                }
            }).filter(Boolean);
            let currentValue = includedLessonsField.value || [];
            currentValue.map((cv:any) => {
                let selectedOptionIndex = selectedOptions.findIndex((opt:any) => {return opt.key === cv.id;});
                cv.deleted = selectedOptionIndex > -1 ? false : true;
                return cv;
            });
            selectedOptions.forEach((opt:any) => {
                if (currentValue.findIndex((v:any) => {return v.id === opt.key;}) < 0) {
                    currentValue.push({
                        id: opt.key,
                        text: opt.text,
                        level: 1,
                        touched: true,
                        deleted: false
                    });
                }
            });
            /*currentValue = currentValue.filter((value:any) => {return allOptions.findIndex((opt:any) => {return opt.key === value.key}) > -1;});
            allOptions.forEach((opt:any) => {
                if (currentValue.findIndex((v:any) => {return v.key === opt.key;}) < 0) {
                    currentValue.push(opt);
                }
            });*/
            
            if (currentValue.length < 1) {
                includedLessonsField.errorMessage = "Pilih minimal 1 pelajaran terkait"
            } else {
                includedLessonsField.errorMessage = undefined;
            }
            includedLessonsField.value = currentValue;
        }
        return fields;
    }

    private _onFormFieldsChanged = (fields:IFormFieldProps[]) => {
        fields = this.showHideFieldsBasedOnRoles(fields);
        fields = this.setIncludedLessonsFieldValue(fields);
        this.setState({formFields: fields, data: undefined});
    }

    public render() {
        return (
            <Panel
                type={PanelType.custom}
                customWidth={"800px"}
                headerText={this.props.data ? "Ubah Data Pengguna" : "Buat Pengguna Baru"}
                isOpen={true}
                onDismiss={() => this._onPanelClosed()}
                closeButtonAriaLabel="Tutup"
                onRenderFooterContent={this.renderPanelFooter}
                isFooterAtBottom={true}>
                {
                    !this.state.loaded ? (
                        <Stack horizontalAlign="baseline" styles={{root: {marginTop: 10}}}>
                            <Spinner label="Mempersiapkan form ..." labelPosition="right"/>
                        </Stack>
                    ) : null }
                {
                    this.state.loaded ? (
                        <Form fields={this.state.formFields} onValueChanged={this._onFormFieldsChanged} defaultValue={this.state.data}/>
                    ) : null
                }
            </Panel>
        );
    }
}
