import React from 'react';
import moment from 'moment';

// import interfaces
import {IFormFieldProps} from './../formProps';

// import subcomponents
import FieldHeader from './../sub/fieldHeader';
import FieldFooter from './../sub/fieldFooter';

// import services
import Validation from './../services/validation';

// import fabric ui components
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { DatePicker } from 'office-ui-fabric-react/lib/DatePicker';

interface IFEDatePickerState {
    field: IFormFieldProps;
    fields: IFormFieldProps[];
}

interface IFEDatePickerProps extends IFEDatePickerState {
    onValueChanged(key:string, value:string, errorMessage?:string): void;
}

export default class FETextField extends React.Component<IFEDatePickerProps, IFEDatePickerState> {
    constructor(props:IFEDatePickerProps) {
        super(props);

        let {field, fields} = this.props;
        this.state = {
            field,
            fields
        }
    }

    public componentWillReceiveProps(props:IFEDatePickerProps) {
        this.setState({field: props.field});
    }

    private _onDateSelected = (date:Date | null | undefined) => {
        let {field, fields} = this.state;
        let value = date ? moment(date).toISOString() : undefined;
        let error = Validation.field(field, value, fields);
        this.props.onValueChanged(field.key, value || "", error);
    }

    private _onFormatDate(date?:Date) {
        return date ? moment(date).format("DD/MM/YYYY") : "";
    }

    public render() {
        let {field} = this.state;
        return (
            <Stack tokens={{childrenGap: 5}}>
                <Stack.Item>
                    <FieldHeader title={field.title} required={field.isRequired} content={field.content}/>
                </Stack.Item>
                <Stack.Item>
                    <DatePicker
                        disabled={field.isDisabled}
                        value={field.value ? moment(field.value).toDate() : undefined} 
                        onSelectDate={this._onDateSelected}
                        formatDate={this._onFormatDate}
                        placeholder="Pilih tanggal..."
                        ariaLabel="Pilih tanggal"/>
                </Stack.Item>
                <Stack.Item>
                    <FieldFooter error={field.errorMessage} description={field.description}/>
                </Stack.Item>
            </Stack>
        );
    }
}